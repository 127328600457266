import "./UserInfo.scss";
import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectGroup, selectRole } from "../../../modules/auth/store/authSlice";
import { Link } from "react-router-dom";
import { useFetchNotifications, useReadNotification } from "./store/usecase";
import { withScope } from "../../../modules/shared/scope-provider";
import { UserGroup } from "configs/constants";
import { UserRole } from "configs/constants";
import { ProfileContext } from "layouts/private/ProfileContext";

const UserInfo = ({
  isUser,
  user,
  hideInfo = false,
  setHideInfo = () => null,
  isAdmin = true
}) => {
  const group = useSelector(selectGroup);
  const role = useSelector(selectRole);
  const dispatch = useDispatch();
  const { notifications, unreadCount } = useFetchNotifications(user);
  const [showDropdown, setShowDropdown] = useState(false);
  const readNotification = useReadNotification();
  const handleLogout = useCallback(async () => {
    dispatch(logout());
  }, [dispatch]);

  const onNotificationClick = useCallback(
    (item) => {
      readNotification(item.id);
    },
    [readNotification]
  );

  const [items, setItems] = useState({});

  const items_client = [
    {
      key: "companyInfo",
      label: (
        <div className="company-info">
          <b>{user?.client?.clientName}</b>
          <div className="company-email">{user?.client?.supportEmail}</div>
        </div>
      )
    },
    {
      key: "apiKey",
      label: (
        <Link to="/api-key">
          <Button type="text" size="small">
            Keys and IDs
          </Button>
        </Link>
      )
    },
    {
      key: "settings",
      label: (
        <Link to="/settings/my-profile">
          <Button type="text" size="small">
            Settings
          </Button>
        </Link>
      )
    },
    {
      key: "logout",
      label: (
        <Button type="text" size="small" onClick={handleLogout} id="logout-btn">
          Log out <LogoutOutlined style={{ color: "#FF3D17" }} />
        </Button>
      )
    }
  ];

  const items_admin = [
    {
      key: "logout",
      label: (
        <Button type="text" size="small" onClick={handleLogout} id="logout-btn">
          Log out <LogoutOutlined style={{ color: "#FF3D17" }} />
        </Button>
      )
    }
  ];

  useEffect(() => {
    {
      group === UserGroup.CLIENT && role !== UserRole.VIEWER
        ? setItems(items_client)
        : setItems(items_admin);
    }
  }, []);

  return (
    <div
      className="header-user-info"
      onClick={() => {
        setShowDropdown(!showDropdown);
        setHideInfo();
      }}
    >
      <Avatar
        notifications={notifications}
        onNotificationClick={onNotificationClick}
        user={user}
        isUser={isUser}
        unreadCount={unreadCount}
      />
      <div className="info" hidden={hideInfo}>
        <div className="mr-2">
          <div>
            <b>{`${user?.firstName || user?.email} ${user?.lastName || ""}`}</b>
          </div>
          <Dropdown menu={{ items }} open={showDropdown}>
            <span>{user?.email}</span>
          </Dropdown>
        </div>
        <div className="ml-2">
          <DownOutlined
            style={{
              fontSize: !isAdmin ? 24 : 12,
              transition: "all 0.3s ease-in-out",
              transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)"
            }}
          />
        </div>
      </div>
    </div>
  );
};

function Avatar({ notifications, onNotificationClick, unreadCount, isUser, user }) {
  const hasNotification = useMemo(() => notifications.length > 0, [notifications]);
  const hasUnread = useMemo(() => isUser && unreadCount > 0, [isUser, unreadCount]);
  const shortName = useMemo(() => (user ? user?.firstName?.[0] : "A"), [user]);
  const profileCtx = useContext(ProfileContext);
  const clientLogo = useMemo(() => profileCtx?.client?.logoPhoto?.url, [profileCtx]);

  const items = [
    {
      key: "notifications",
      label: (
        <div className={"notifications"}>
          {notifications.map((it) => (
            <div key={it.id} className={"item"} onClick={() => onNotificationClick(it)}>
              {it.message} {!it.read && <div className={"unread"} />}
            </div>
          ))}
        </div>
      )
    }
  ];
  if (!hasNotification) {
    return (
      <div className={"avatar"}>
        <div
          className="first-chacracter"
          style={{ position: "relative", overflow: "hidden" }}
        >
          {clientLogo ? (
            <img
              src={clientLogo}
              alt="client-logo"
              width="32"
              height="32"
              style={{ position: "absolute", top: "0px", left: "0px" }}
            />
          ) : (
            <p>{shortName}</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <Dropdown trigger={"click"} placement={"bottom"} menu={{ items }}>
      <div className={"avatar"}>
        {hasUnread && <div className="notification">{unreadCount}</div>}
        <div className="first-chacracter clickable">
          {clientLogo ? (
            <img src={clientLogo} alt="client-logo" width="32" height="32" />
          ) : (
            <p>{shortName}</p>
          )}
        </div>
      </div>
    </Dropdown>
  );
}

export default withScope(UserInfo);
