import React, { useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { AddIcon } from "../../../../../components/svgs";
import AdminBreadCrumb from "../../../../../components/AdminBreadCrumb";
import "./RoutesHeader.scss";
import { FilterIcon } from "components/svgs/FilterIcon";
import { ExportIcon } from "components/svgs/ExportIcon";
import { ImportIcon } from "components/svgs/ImportIcon";
import { ToolTipTitle } from "components/tooltip-title/TooltipTitle";
import { ProfileContext } from "layouts/private/ProfileContext";
import { useHistory } from "react-router-dom";

const RouteHeader = ({
  breadCrumbs,
  showFilter,
  showCreateButton,
  hasButton = true,
  setShowFilter,
  setShowImport,
  onExport,
  exporting
}) => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const history = useHistory();
  return (
    <div className={`order-header-component my-4 ${showFilter && "filter"}`}>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      {hasButton && (
        <div className="buttons d-flex flex-justify-end gap-3">
          <Button
            size={showFilter ? "normal" : "small"}
            className={`svg-icon ${showFilter && "filter"} d-flex flex-row`}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filter <FilterIcon />
          </Button>
          <Button
            type="third"
            size="small"
            className={`svg-icon d-flex flex-row `}
            onClick={onExport}
            loading={exporting}
          >
            Export <ExportIcon />
          </Button>

          {showCreateButton && (
            <Tooltip
              title={profile?.canCreateRoute ? "" : <ToolTipTitle />}
              color="#43C1C2"
            >
              <Button
                type="third"
                size="small"
                className={`svg-icon  d-flex flex-row`}
                onClick={() => {
                  history.push("/import-route");
                }}
                disabled={!profile?.canCreateRoute}
              >
                Import <ImportIcon />
              </Button>
            </Tooltip>
          )}

          {showCreateButton && (
            <Tooltip
              title={profile?.canCreateRoute ? "" : <ToolTipTitle />}
              color="#43C1C2"
            >
              <Link to="/route/new">
                <Button
                  type="secondary"
                  size={"small"}
                  disabled={!profile?.canCreateRoute}
                >
                  Create New Job &nbsp;
                  <AddIcon />
                </Button>
              </Link>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default RouteHeader;
