import { Col, Row, Spin, Menu, Button } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./components/Filter";
import { Chart } from "react-chartjs-2";
import "chart.js/auto";
import "./index.scss";
import { useEffect } from "react";
import { dashboardApi } from "./store/api";
import prepareChartData, {
  buildFirstLevelFilterStatistic,
  calculateChartTotal
} from "./helper";
import moment from "moment";
import _pick from "lodash/pick";
import { DATE_FORMAT_FRONTEND } from "configs/constants";
import { setError } from "app/global/slice";
import { useDispatch } from "react-redux";
import AdminBreadCrumb from "components/AdminBreadCrumb";
import { useHistory } from "react-router-dom";
import WelcomeOverlay from "./components/WelcomOverlay";
import { useLocalStorage } from "helper/hooks/useLocalStorage";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const chartOptions = {
  legend: {
    position: "right"
  }
};

const breadCrumbs = [{ text: "DASHBOARD" }];

const DashboardPage = ({ isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [statisticData, setStatisticData] = useState({});
  const [statisticChart, setStatisticChart] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState("/dashboard");
  const [welcomeToDashboard, setWelcomeToDashboard] = useLocalStorage("welcome", true);
  useEffect(() => {
    const today = moment().format(DATE_FORMAT_FRONTEND);
    loadStatistic({ dateRange: [today, today] });
    // eslint-disable-next-line
  }, []);

  const loadStatistic = async (params) => {
    try {
      setLoading(true);
      const parsedParams = { ...params };
      if (params?.dateRange) {
        delete parsedParams.dateRange;
        parsedParams.startDate = params.dateRange[0];
        parsedParams.endDate = params.dateRange[1];
      }
      const statistic = await dashboardApi.getStatistic({
        ..._pick(params, ["clientId", "territoryId", "city"]),
        filter: buildFirstLevelFilterStatistic(parsedParams)
      });
      setStatisticData(statistic);
      setStatisticChart(prepareChartData(statistic));
    } catch (err) {
      const error = JSON.parse(err?.message || "{}");
      dispatch(setError(error));
    } finally {
      setLoading(false);
    }
  };

  const onClick = (e) => {
    const key = e?.key;
    setCurrent(key);
    key && history.push(`${key}`);
  };

  // bloquear scroll
  const location = useLocation();
  /* useEffect(() => {
    if (location.pathname === "/dashboard") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    console.log("location: ", location);
  }, [welcomeToDashboard, location]); */
  const handleScrollLock = () => {
    if (welcomeToDashboard) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };
  useEffect(() => {
    handleScrollLock();
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [welcomeToDashboard]);

  return (
    <div>
      {!isAdmin && welcomeToDashboard && (
        <WelcomeOverlay setShowWelcome={setWelcomeToDashboard} />
      )}
      <Spin spinning={loading}>
        <div className="dashboard">
          {isAdmin ? (
            <div
              style={{
                display: "grid",
                position: "relative"
              }}
            >
              <AdminBreadCrumb breadCrumbs={breadCrumbs} />
              <Button
                onClick={() => setShowFilter(!showFilter)}
                style={{
                  ...styles.filterButton,
                  top: "-10px"
                }}
              >
                Filter
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                position: "relative"
              }}
            >
              <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="/dashboard">Operations</Menu.Item>
              </Menu>
              <Button
                onClick={() => setShowFilter(!showFilter)}
                style={{ ...styles.filterButton }}
              >
                Filter
              </Button>
            </div>
          )}

          {showFilter && <Filter isAdmin={isAdmin} handleClickFilter={loadStatistic} />}

          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32
            }}
            className="chart-list"
          >
            <Col span={6}>
              <div className="chart-item">
                <h5 className="title">Total Jobs by Status</h5>
                <h3 className="total">
                  {calculateChartTotal(statisticData.jobsByStatus)}
                </h3>
                {statisticChart.jobsByStatusChartData && (
                  <Chart
                    type="pie"
                    data={statisticChart.jobsByStatusChartData}
                    options={chartOptions}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <div className="chart-item">
                <h5 className="title">Total Deliveries by Status</h5>
                <h3 className="total">
                  {calculateChartTotal(statisticData.stopsByStatus)}
                </h3>
                {statisticChart.stopsByStatusChartData && (
                  <Chart
                    type="pie"
                    data={statisticChart.stopsByStatusChartData}
                    options={chartOptions}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <div className="chart-item">
                <h5 className="title">Total Jobs by Vehicle Type</h5>
                <h3 className="total">
                  {calculateChartTotal(statisticData.jobsByVehicleType)}
                </h3>
                {statisticChart.jobsByVehicleChartData && (
                  <Chart
                    type="pie"
                    data={statisticChart.jobsByVehicleChartData}
                    options={chartOptions}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <div className="chart-item">
                <h5 className="title">Total Packages by Size</h5>
                <h3 className="total">
                  {calculateChartTotal(statisticData.packagesBySize)}
                </h3>
                {statisticChart.packagesBySizeChartData && (
                  <Chart
                    type="pie"
                    data={statisticChart.packagesBySizeChartData}
                    options={chartOptions}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <div className="chart-item">
                <h5 className="title">Total Jobs by Service</h5>
                <h3 className="total">
                  {calculateChartTotal(statisticData.jobsByService)}
                </h3>
                {statisticChart.jobsByServiceChartData && (
                  <Chart
                    type="pie"
                    data={statisticChart.jobsByServiceChartData}
                    options={chartOptions}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

const styles = {
  filterButton: {
    maxWidth: 100,
    position: "absolute",
    right: 0
  }
};

export default DashboardPage;
