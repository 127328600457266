import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { DrawIcon } from "components/svgs/DrawIcon";
import { DEFAULT_CENTER } from "configs/constants";
import $ from "jquery";
import React, { useState } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import Polygon from "./Polygon";
import PlacePolygonSearch from "./PlacePolygonSearch";
import "./PolygonMapboxForm.scss";

const Map = ReactMapboxGl({
  accessToken: window.REACT_APP_MAPBOX_KEY,
  polygon: {
    shapeOptions: {
      color: "#fff"
    }
  }
});

const PolygonMapboxForm = ({
  onCompleteDraw,
  coords,
  center = DEFAULT_CENTER,
  isEditable = false,
  showTitle = true,
  title = "Polygon in Map"
}) => {
  const [mapCenter, setMapCenter] = useState(center);

  const onDrawCreate = ({ features }) => {
    onCompleteDraw(features[0].geometry.coordinates);
    $(".mapbox-gl-draw_trash").trigger("click");
  };

  const onDrawDelete = () => {
    onCompleteDraw([]);
  };

  const handleClickRedraw = () => {
    onCompleteDraw([]);
    $(".mapbox-gl-draw_trash").trigger("click");
  };

  const handleSelectPlace = (coordinates) => {
    if (
      !coordinates?.length ||
      !coordinates[0]?.length ||
      coordinates[0][0]?.length !== 2
    )
      return;
    setMapCenter(coordinates[0][0]);
    onCompleteDraw(coordinates);
  };

  return (
    <div className="polygon-mapbox-component">
      <div className="wrap-title">
        {showTitle && <div className="title">{title}</div>}
        <div className="redraw-action" onClick={handleClickRedraw}>
          Redraw Polygon
          <DrawIcon />
        </div>
      </div>
      <Map
        style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
        containerStyle={{
          height: "400px"
        }}
        center={mapCenter}
      >
        {!isEditable && <Polygon coords={coords} />}

        <DrawControl
          onDrawDelete={onDrawDelete}
          onDrawCreate={onDrawCreate}
          controls={{
            line_string: false,
            combine_features: false,
            uncombine_features: false,
            point: false,
            trash: true
          }}
          displayControlsDefault={true}
        />
        <PlacePolygonSearch
          onSelect={handleSelectPlace}
          className="place-polygon-seach-wrapper"
        />
      </Map>
      <div className="hint">Please press enter when complete polygon!</div>
    </div>
  );
};
export default PolygonMapboxForm;
