import { Card } from "antd";
import { RoutesIcon } from "components/svgs/menu/RoutesIcon";
import React from "react";
import { Link } from "react-router-dom";

const HomeCard = ({
  icon = <RoutesIcon width={40} height={40} fill="#1278F1" />,
  title,
  description,
  buttonText,
  buttonLink = "#"
}) => {
  return (
    <Card>
      <div className="text-center">
        <div className="card-icon">{icon}</div>
        <p className="card-title">{title}</p>
        <p className="card-description">{description}</p>
        <Link className="card-cta" to={buttonLink}>
          {buttonText}
        </Link>
      </div>
    </Card>
  );
};

export default HomeCard;
