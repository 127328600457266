import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as firebaseDB from "firebase/database";
import { Spin, notification } from "antd";
import ChartContainer from "modules/shared/PickupDropoff/chart/ChartContainer";
import MapBox from "modules/shared/PickupDropoff/mapbox/Mapbox";
import { ONE_HOUR_PX } from "modules/shared/PickupDropoff/constants";
import { selectDraftId } from "modules/client/active-orders/store/orderSlice";
import PickupDropoffContainer from "modules/shared/PickupDropoff/PickupDropoffContainer";
import withFetchOne from "hocs/withFetchOne";
import { realtimeDB } from "configs/firebaseConfig";
import { useDriverLocationByRoute } from "modules/shared/driver-location";
import { selectRouteDispatch } from "../store/slice";
import { doGetRoutesDispatch } from "../store/thunks";
import { StopSearch } from "./StopSearch";

import "./RouteDispatchPage.scss";

const RouteDispatchPage = ({ isAdmin }) => {
  const params = useParams();
  const historyHook = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const dispatch = useDispatch();
  const draftIdSelector = useSelector(selectDraftId);
  const user = useSelector((state) => state.auth.user);
  const routeDispatch = useSelector(selectRouteDispatch);
  const currentDriver = useDriverLocationByRoute(routeDispatch?.id || "");
  const stopContainerHolder = useMemo(() => ({}), []);
  const allowOptimize =
    ["edit-stop", "delete-stop"].includes(queryParams.get("from")) ||
    routeDispatch?.locations?.filter(({ routeId }) => routeId === "unscheduled")?.length >
      0;

  const [oneHourPx, setOneHourPx] = useState(ONE_HOUR_PX);
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    if (!draftIdSelector || !updating) return;

    const routeUpdatingPathRef = `/updating-route-completed/${String([
      user.sub,
      draftIdSelector
    ])}`;
    const routeUpdatingDBRef = firebaseDB.ref(realtimeDB, routeUpdatingPathRef);

    firebaseDB.onValue(routeUpdatingDBRef, (snapshot) => {
      const res = snapshot.val();
      if (!res) return;

      if (res?.payload?.data?.error) {
        notification.error({ massage: res?.payload?.data?.error?.message });
        setUpdating(false);
        return;
      }

      if (res.event === "route.updating.completed") {
        if (queryParams.has("from")) {
          queryParams.delete("from");
          historyHook.replace({
            search: queryParams.toString()
          });
        }
        notification.success({ message: "Updated successfully!" });
        setUpdating(false);
        dispatch(doGetRoutesDispatch(params.id));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftIdSelector, updating]);

  const handleConfirmRoute = () => {};

  const onStopClick = (stop) => {
    stopContainerHolder.focus?.(stop);
  };

  const handleZoomIn = () => {
    setOneHourPx(oneHourPx + oneHourPx / 10);
  };
  const handleZoomOut = () => {
    setOneHourPx(oneHourPx - oneHourPx / 10);
  };

  return (
    <Spin spinning={updating}>
      <div className="route-dispatch-detail">
        <PickupDropoffContainer
          holder={stopContainerHolder}
          stops={routeDispatch?.locations}
          routes={routeDispatch?.routes}
          oneHourPx={oneHourPx}
        >
          {({
            handleUpdateAllRoutes,
            handleUpdateRoute,
            handleClickMarker,
            handleHoverMarker,
            popupData,
            handleClosePopup,
            goToCoupleStop,
            handleStopDetail,
            markerActive,
            center,
            containerStops,
            originStops,
            containerRoutes,
            mapStops,
            bounds,
            displayAll,
            driverDetail,
            setDriverDetail,
            showDriverDetail,
            setShowDriverDetail,
            groupStops,
            onDragEnd,
            onDragStart,
            handleClickHistory,
            history,
            earliestRoute,
            disabledNextButton,
            disabledPreviousButton,
            dndStatus
          }) => (
            <>
              <MapBox
                handleClosePopup={handleClosePopup}
                handleClickMarker={handleClickMarker}
                handleHoverMarker={handleHoverMarker}
                stops={mapStops}
                center={center}
                routes={containerRoutes}
                popupData={popupData}
                goToCoupleStop={goToCoupleStop}
                height={600}
                bounds={bounds}
                currentDriver={currentDriver}
                driverDetail={driverDetail}
                setDriverDetail={setDriverDetail}
                showDriverDetail={showDriverDetail}
                setShowDriverDetail={setShowDriverDetail}
                isAdmin={isAdmin}
              />
              <ChartContainer
                handleStopDetail={handleStopDetail}
                originStops={originStops}
                showConfirmButton={false}
                handleConfirmRoute={handleConfirmRoute}
                routes={containerRoutes}
                stops={containerStops}
                route={routeDispatch}
                height={500}
                order={routeDispatch}
                handleClickMarker={handleClickMarker}
                handleHoverMarker={handleHoverMarker}
                handleClosePopup={handleClosePopup}
                isShowButtonAction={false}
                setDriverDetail={setDriverDetail}
                setShowDriverDetail={setShowDriverDetail}
                handleUpdateRoute={handleUpdateRoute}
                handleUpdateAllRoutes={handleUpdateAllRoutes}
                displayAll={displayAll}
                groupStops={groupStops}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                handleClickHistory={handleClickHistory}
                history={history}
                earliestRoute={earliestRoute}
                disabledNextButton={disabledNextButton}
                disabledPreviousButton={disabledPreviousButton}
                dndStatus={dndStatus}
                oneHourPx={oneHourPx}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
                allowOptimize={allowOptimize}
                onSetUpdating={setUpdating}
              />
              <StopSearch stops={containerStops} onStopClick={onStopClick} />
            </>
          )}
        </PickupDropoffContainer>
      </div>
    </Spin>
  );
};

export default withFetchOne(RouteDispatchPage, {
  fetchData: doGetRoutesDispatch
});
