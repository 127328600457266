import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  Row,
  Col,
  Spin,
  Table,
  Tooltip
} from "antd";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import DefaultMapbox from "modules/client/active-orders/components/mapbox/DefaultMapbox";
import AddressInput from "components/inputs/AddressInput";
import { DEFAULT_CENTER } from "configs/constants";
import { PHONE_VALIDATION } from "configs/constants";
import "../../active-orders/components/form/AddressForm.scss";
import "./PoolAddressForm.scss";
import { formatToUSD } from "helper/util";
import Cookies from "universal-cookie";
import LocationsDropdown from "./LocationsDropdown";
import request from "helper/request";
import lodash from "lodash";
import { ProfileContext } from "layouts/private/ProfileContext";

const cookies = new Cookies();

const PoolAddressForm = ({
  selectedPackage,
  submit,
  onSelectPickupAddress,
  onSelectDropoffAddress,
  onPackageFormChange,
  packageForm,
  packageTypeList,
  setPackageData,
  selectedPackageIndex,
  packageData
}) => {
  const disabledDate = useCallback((current) => {
    return current && current < moment().endOf("day");
  }, []);
  const [packageEstimate, setPackageEstimate] = useState([]);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [savedLocations, setSavedLocations] = useState([]);
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile);
  const getSavedLocations = useCallback(async () => {
    const response = await request.get("/saved-locations/all-locations");
    setSavedLocations(response);
  }, [setSavedLocations]);

  useEffect(() => {
    getSavedLocations();
    // eslint-disable-next-line
  }, []);

  const handleSelectDataPickup = useCallback(
    (locationId) => {
      const value = savedLocations.find((location) => location.id === locationId);
      if (value) {
        setPackageData((prevState) => {
          const tmp = [...prevState];
          tmp[selectedPackageIndex] = {
            ...tmp[selectedPackageIndex],
            pickupAddress: value.address,
            pickupLat: value.lat,
            pickupLng: value.lng,
            pickupContactName: value.contactName,
            pickupContactEmail: value.contactEmail,
            pickupContactPhone: value.contactPhone,
            pickupStopName: value.locationName
          };
          return tmp;
        });

        // 2025-02-25 - fixing validation on saved location phone number
        packageForm.setFieldsValue({
          pickupAddress: value.address,
          pickupContactName: value.contactName,
          pickupContactEmail: value.contactEmail,
          pickupContactPhone: value.contactPhone,
          pickupStopName: value.locationName
        });
      }
    },
    [setPackageData, savedLocations, selectedPackageIndex, packageForm]
  );

  const handleSelectDataDropoff = useCallback(
    (locationId) => {
      const value = savedLocations.find((location) => location.id === locationId);
      if (value) {
        setPackageData((prevState) => {
          const tmp = [...prevState];
          tmp[selectedPackageIndex] = {
            ...tmp[selectedPackageIndex],
            dropoffAddress: value.address,
            dropoffLat: value.lat,
            dropoffLng: value.lng,
            dropoffContactName: value.contactName,
            dropoffContactPhone: value.contactPhone,
            dropoffStopName: value.locationName,
            note: value.note
          };
          return tmp;
        });

        // 2025-02-25 - fixing validation on saved location phone number
        packageForm.setFieldsValue({
          dropoffAddress: value.address,
          dropoffContactName: value.contactName,
          dropoffContactPhone: value.contactPhone,
          dropoffStopName: value.locationName,
          note: value.note
        });
      }
    },
    [setPackageData, savedLocations, selectedPackageIndex, packageForm]
  );

  const handlePickupLocationChange = useCallback(
    (value) => {
      /* 2025-02-25 - fixing validation on saved location phone number
      This was just updating the pickup stop name, need to force update phone
      WIP - DOES NOT WORK 
      */

      // get savedLocation by name
      const savedLocation = savedLocations.find((loc) => loc.name === value);
      console.log("savedLocation", savedLocation);

      // now update both packageData and form for saved location
      if (savedLocation) {
        setPackageData((prevState) => {
          const tmp = [...prevState];
          tmp[selectedPackageIndex] = {
            ...tmp[selectedPackageIndex],
            pickupStopName: value,
            pickupContactPhone: savedLocation.contactPhone
          };
          packageForm.pickupStopName = value;
          packageForm.pickupContactPhone = savedLocation.contactPhone;
          return tmp;
        });
        // not saved location? Just update package data as form changes
      } else {
        setPackageData((prevState) => {
          const tmp = [...prevState];
          tmp[selectedPackageIndex] = {
            ...tmp[selectedPackageIndex],
            pickupStopName: value
          };
          return tmp;
        });
      }
    },
    [setPackageData, selectedPackageIndex, savedLocations, packageForm]
  );

  const handleNotesChange = useCallback(
    (value) => {
      setPackageData((prevState) => {
        const tmp = [...prevState];
        tmp[selectedPackageIndex] = {
          ...tmp[selectedPackageIndex],
          note: value
        };
        return tmp;
      });
    },
    [setPackageData, selectedPackageIndex]
  );

  const handleDropoffLocationChange = useCallback(
    (value) => {
      setPackageData((prevState) => {
        const tmp = [...prevState];
        tmp[selectedPackageIndex] = {
          ...tmp[selectedPackageIndex],
          dropoffStopName: value
        };
        return tmp;
      });
    },
    [setPackageData, selectedPackageIndex]
  );

  const calculateEstimate = useCallback(async (packageTypeId) => {
    const tmpPackageData = packageData;

    tmpPackageData[selectedPackageIndex] = {
      ...tmpPackageData[selectedPackageIndex],
      packageTypeId
    };

    try {
      const packagesForCalculating = tmpPackageData.filter(
        (tmPkg) => tmPkg.packageTypeId
      );

      setEstimateLoading(true);
      const calculateResponse = await request.post("/pool-pricing/calculate-price", {
        packages: packagesForCalculating
      });

      const hasDiscount = !!calculateResponse?.discount;

      setShowDiscount(hasDiscount);

      const packageType = packageTypeList.find((pkgType) => pkgType.id === packageTypeId);
      let tmpPackageEstimate = packageEstimate;

      tmpPackageEstimate[selectedPackageIndex] = {
        key: tmpPackageData[selectedPackageIndex].id,
        ...tmpPackageEstimate[selectedPackageIndex],
        ...calculateResponse,
        selectedPackageIndex,
        packageTypeId: packageType.id,
        name: packageType.name,
        poolPrice: packageType.poolPrice,
        quantity: calculateResponse[`total${packageType.name}`]
      };

      if (tmpPackageEstimate[selectedPackageIndex].name === packageType.name) {
        const unEmptyPackageEstimate = tmpPackageEstimate.filter(
          (tmpPkgEstimate) => tmpPkgEstimate
        );

        let consolidateTmpPackageEstimate = consolidatePkgEstimate(
          unEmptyPackageEstimate,
          calculateResponse
        );

        consolidateTmpPackageEstimate = consolidateTmpPackageEstimate.map(
          (consolidatedPkgEstimate) => ({
            ...consolidatedPkgEstimate,
            quantity: consolidatedPkgEstimate[`total${consolidatedPkgEstimate.name}`]
          })
        );

        return setPackageEstimate([...consolidateTmpPackageEstimate]);
      }

      tmpPackageEstimate = tmpPackageEstimate.map((tmpPkgEstimate) => ({
        ...tmpPkgEstimate,
        quantity: tmpPkgEstimate[`total${tmpPkgEstimate.name}`]
      }));

      setPackageEstimate([...tmpPackageEstimate]);
    } catch (error) {
      console.log(error);
    } finally {
      setEstimateLoading(false);
    }
  });

  const consolidatePkgEstimate = (pkgEstimateArr, calculateResponse) => {
    const pkgEstNames = pkgEstimateArr.map((pkgEst) => pkgEst.name);
    const uniqueNames = lodash.uniqBy(pkgEstNames);
    const consolidateTmpPackageEstimate = uniqueNames.map((name) => {
      const matchName = pkgEstimateArr.filter((item) => item.name === name);

      const quantities = matchName.map((pkgEst) => pkgEst.quantity);
      const quantity = Math.max(...quantities);
      const currentPackageType = packageTypeList.find((pkgType) => pkgType.name === name);

      return {
        ...calculateResponse,
        selectedPackageIndex,
        packageTypeId: currentPackageType.id,
        name,
        poolPrice: currentPackageType.poolPrice,
        quantity
      };
    });

    return consolidateTmpPackageEstimate;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record) => {
        return <>x{record.quantity}</>;
      }
    },
    {
      title: "Rate",
      dataIndex: "poolPrice",
      render: (_, record) => {
        return <>{formatToUSD(record?.poolPrice || 0)}</>;
      }
    },
    {
      title: "Price",
      dataIndex: "total",
      render: (_, record) => {
        return <>{formatToUSD((record?.poolPrice * record?.quantity || 0).toFixed(2))}</>;
      }
    }
  ];

  const getPromoCodeName = () => {
    return profile?.client.promoCodes.find(
      (code) => code.discountName === "FLEXIOPOOL10OFF"
    )?.discountName;
  };

  return (
    <Form
      onValuesChange={(val) => {
        onPackageFormChange(val);
        // calculateEstimate(val);
      }}
      scrollToFirstError={true}
      layout="vertical"
      form={packageForm}
      hideRequiredMark
      colon={false}
      initialValues={selectedPackage}
      onFinish={() => submit()}
    >
      <div className="AddressForm">
        {/* PICKUP */}
        <div className="pool-address">
          <div className="form">
            <Form.Item
              label="Pickup Stop Name"
              name={["pickupStopName"]}
              fieldKey={["pickupStopName"]}
              value={selectedPackage.pickupStopName}
              rules={[{ required: true, message: "Required" }]}
            >
              <LocationsDropdown
                name="pickupStopName"
                options={savedLocations}
                handlePickup={handleSelectDataPickup}
                locationName={selectedPackage.pickupStopName}
                handleNewLocationName={handlePickupLocationChange}
                searchValue={packageData[selectedPackageIndex].pickupStopName}
              />
            </Form.Item>
            <Form.Item
              label="Pickup Address *"
              name={["pickupAddress"]}
              fieldKey={["pickupAddress"]}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject("Required");
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <AddressInput
                placeholder="Pickup Address"
                onSelect={(a) => onSelectPickupAddress(a)}
                address={{
                  address: selectedPackage.pickupAddress,
                  lngLat: [selectedPackage.pickupLng, selectedPackage.pickupLat]
                }}
              />
            </Form.Item>
            <Form.Item
              label="Pickup Contact Name *"
              name={["pickupContactName"]}
              fieldKey={["pickupContactName"]}
              rules={[{ required: true, message: "Required" }]}
              value={selectedPackage.pickupContactName}
            >
              <Input name="pickupContactName" placeholder="Pickup Contact Name" />
            </Form.Item>

            <Form.Item
              label="Pickup Contact Email"
              name={["pickupContactEmail"]}
              fieldKey={["pickupContactEmail"]}
              value={selectedPackage.pickupContactEmail}
            >
              <Input name="pickupContactEmail" placeholder="Pickup Contact Email" />
            </Form.Item>
            <Form.Item
              label="Pickup Phone Number *"
              name={["pickupContactPhone"]}
              fieldKey={["pickupContactPhone"]}
              value={selectedPackage.pickupContactPhone}
              rules={[
                {
                  required: true,
                  message: "Required",
                  pattern: PHONE_VALIDATION
                }
              ]}
            >
              <PhoneInput
                country="us"
                excludeCountries={["pr"]}
                enableSearch
                enableAreaCodes={true}
                name="pickupContactPhone"
                placeholder="Pickup Phone Number"
              />
            </Form.Item>
            <Form.Item
              label="Pickup Date *"
              name={["pickupDate"]}
              fieldKey={["pickupDate"]}
              rules={[{ required: true, message: "Required" }]}
              value={selectedPackage.pickupDate}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </div>
          <div className="map">
            <DefaultMapbox
              address={{
                address: selectedPackage.pickupAddress,
                lngLat: [
                  selectedPackage.pickupLng || DEFAULT_CENTER[0],
                  selectedPackage.pickupLat || DEFAULT_CENTER[1]
                ]
              }}
              setAddress={onSelectPickupAddress}
            />
          </div>
        </div>

        {/* DROPOFF */}
        <div className="pool-address">
          <div className="form">
            <Form.Item
              label="Dropoff Stop Name"
              name={["dropoffStopName"]}
              fieldKey={["dropoffStopName"]}
              value={selectedPackage.dropoffStopName}
              rules={[{ required: true, message: "Required" }]}
            >
              <LocationsDropdown
                name="dropoffStopName"
                options={savedLocations}
                handlePickup={handleSelectDataDropoff}
                locationName={selectedPackage.dropoffStopName}
                handleNewLocationName={handleDropoffLocationChange}
                searchValue={packageData[selectedPackageIndex].dropoffStopName}
              />
            </Form.Item>
            <Form.Item
              label="Dropoff Address *"
              name={["dropoffAddress"]}
              fieldKey={["dropoffAddress"]}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject("Required");
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <AddressInput
                placeholder="Dropoff Address"
                onSelect={(a) => onSelectDropoffAddress(a)}
                address={{
                  address: selectedPackage.dropoffAddress,
                  lngLat: [selectedPackage.dropoffLng, selectedPackage.dropoffLat]
                }}
              />
            </Form.Item>
            <Form.Item
              label="Dropoff Contact Name *"
              name={["dropoffContactName"]}
              fieldKey={["dropoffContactName"]}
              rules={[{ required: true, message: "Required" }]}
              value={selectedPackage.dropoffContactName}
            >
              <Input name="dropoffContactName" placeholder="Dropoff Contact Name" />
            </Form.Item>

            <Form.Item
              label="Dropoff Phone Number *"
              name={["dropoffContactPhone"]}
              fieldKey={["dropoffContactPhone"]}
              rules={[
                {
                  required: true,
                  message: "Required",
                  pattern: PHONE_VALIDATION
                }
              ]}
              value={selectedPackage.dropoffContactPhone}
            >
              <PhoneInput
                country="us"
                excludeCountries={["pr"]}
                enableSearch
                enableAreaCodes={true}
                name="dropoffContactPhone"
                placeholder="Dropoff Phone Number"
              />
            </Form.Item>
          </div>
          <div className="map">
            <DefaultMapbox
              address={{
                address: selectedPackage.dropoffAddress,
                lngLat: [
                  selectedPackage.dropoffLng || DEFAULT_CENTER[0],
                  selectedPackage.dropoffLat || DEFAULT_CENTER[1]
                ]
              }}
              setAddress={onSelectDropoffAddress}
            />
          </div>
        </div>
        <div className="pool-address mt-1">
          <Form.Item
            // style={{ padding: "0rem 3rem 0 0" }}
            label="Package Size *"
            name={["packageTypeId"]}
            fieldKey={["packageTypeId"]}
            value={selectedPackage.packageTypeId}
            rules={[{ required: true, message: "Required" }]}
          >
            <Radio.Group>
              {packageTypeList.map((pkgType) => {
                return (
                  <Radio
                    key={pkgType.id}
                    value={pkgType.id}
                    className="radio-container"
                    onClick={() => calculateEstimate(pkgType.id)}
                  >
                    <div className="package-radio">
                      <h3>{pkgType?.name}</h3>
                      <p>
                        Max Length: {pkgType?.maxLength}{" "}
                        {window.REACT_APP_DD_ENV === "ccpexpress" ? "cm" : "in"}
                      </p>
                      <p>
                        Max Weight: {pkgType?.maxWeight}{" "}
                        {window.REACT_APP_DD_ENV === "ccpexpress" ? "kg" : "lb"}
                      </p>
                      <p>
                        Max Volume: {pkgType?.maxVolume}{" "}
                        {window.REACT_APP_DD_ENV === "ccpexpress" ? (
                          <>&#13221;</>
                        ) : (
                          "cuft"
                        )}
                      </p>
                      <h4 className="pool-price">{formatToUSD(pkgType?.poolPrice)}</h4>
                    </div>
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Tooltip title={!packageEstimate.length && "Please select a Package Size"}>
            <Form.Item
              label="Quantity"
              name={["quantity"]}
              fieldKey={["quantity"]}
              value={selectedPackage.quantity}
            >
              <Input
                type="number"
                placeholder="Quantity"
                min={1}
                max={10}
                size="small"
                style={{ width: 100 }}
                disabled={
                  !packageData[selectedPackageIndex]?.packageTypeId ? true : false
                }
                onClick={() => {
                  calculateEstimate(selectedPackage.packageTypeId);
                }}
              />
            </Form.Item>
          </Tooltip>
        </div>
        <Form.Item
          label="Shopify Order Id"
          name={["shopifyOrderId"]}
          fieldKey={["shopifyOrderId"]}
          style={{ marginBottom: "5em" }}
          value={selectedPackage.shopifyOrderId}
        >
          <Input type="text" placeholder="Shopify Order ID" />
        </Form.Item>
        <Form.Item
          label="Note"
          name={["note"]}
          fieldKey={["note"]}
          style={{ marginBottom: "3em" }}
          value={selectedPackage.note}
        >
          <Input.TextArea
            placeholder="Note"
            onChange={(e) => {
              handleNotesChange(e.target.value);
            }}
          />
        </Form.Item>
        {!!profile?.client.promoCodes.length && (
          <Form.Item label="Promo Code" style={{ marginBottom: "5em" }}>
            <Input value={getPromoCodeName()} disabled />
          </Form.Item>
        )}
        <Form.Item label="Quote:">
          <Table
            columns={columns}
            dataSource={packageEstimate}
            loading={estimateLoading}
            pagination={false}
          />
          <div className="d-flex mt-2" style={{ justifyContent: "space-between" }}>
            <small>
              {showDiscount &&
                "* Discount is calculated when you choose 5 or more packages."}
            </small>
            <p className="text-right">
              {showDiscount && (
                <>
                  <div>
                    <p>
                      Before discount: {formatToUSD(packageEstimate[0]?.beforeDiscount)}
                    </p>
                    <p>Volume Discount: -{formatToUSD(packageEstimate[0]?.discount)}</p>
                    <p>
                      Promo Code Discount: -
                      {formatToUSD(packageEstimate[0]?.totalPricePromoDiscount)}
                    </p>
                  </div>
                </>
              )}
            </p>
          </div>
          <div>
            <h3 className="text-right">
              Total Price:{" "}
              {formatToUSD(packageEstimate[packageEstimate.length - 1]?.finalPrice || 0)}
            </h3>
          </div>
        </Form.Item>

        <div className="footer mt-5">
          <Form.Item>
            <Button size="large" onClick={() => {}}>
              Reset
            </Button>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Create Pool Order
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default PoolAddressForm;
