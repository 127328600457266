import { Card } from "antd";
import { RoutesIcon } from "components/svgs/menu/RoutesIcon";
import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({
  icon = <RoutesIcon width={40} height={40} fill="#1278F1" />,
  title,
  description,
  bulletPointOne,
  bulletPointTwo,
  bulletPointThree,
  buttonText,
  buttonLink = "#"
}) => {
  return (
    <Card>
      <div className="text-center">
        <div className="card-icon">{icon}</div>
        <p className="card-title">{title}</p>
        {/* <p className="card-description">{description}</p> */}
        <ul className="card-bullets">
          <li>{bulletPointOne}</li>
          <li>{bulletPointTwo}</li>
          <li>{bulletPointThree}</li>
        </ul>
        <Link className="card-cta" to={buttonLink}>
          {buttonText}
        </Link>
      </div>
    </Card>
  );
};

export default ServiceCard;
