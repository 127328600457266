import React, { useCallback, useEffect } from "react";
import { Button, DatePicker, Form, Input, message, Select, Switch } from "antd";
import { DAY_DATE_FORMAT } from "configs/constants";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getClientList } from "modules/admin/clients/store/thunks";

const PromoCodeEditForm = ({ form, updatePromoCode }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const dispatch = useDispatch();
  const clients = useSelector(selectAllClients);

  useEffect(() => {
    dispatch(getClientList({}));
  }, []);

  const submitForm = useCallback((submittedData) => {
    const updateReqData = {
      ...submittedData,
      amountOff: parseInt(submittedData.amountOff) || 0,
      percentOff: parseInt(submittedData.percentOff) || 0,
      expiryDate: moment(submittedData.expiryDate).format()
    };

    if (updateReqData.amountOff && updateReqData.percentOff) {
      return messageApi.open({
        type: "error",
        content: "You cannot have an amount and percent discount. Choose one."
      });
    }

    updatePromoCode(updateReqData);
    history.push("/admin/pool/promo-codes");
  }, []);

  return (
    <div className="admin-client-edit admin-create-update-client">
      {contextHolder}
      <div className="admin-content form-content">
        <h2 className="title">Edit Promo Code</h2>
        <div className="ClientForm">
          <Form form={form} layout="vertical" onFinish={submitForm}>
            <div className="form-content">
              <Form.Item name="discountName" label="Promo Code">
                <Input placeholder="Promo Code" />
              </Form.Item>
              <Form.Item name="amountOff" label="Discount Amount">
                <Input placeholder="Discount Amount" prefix="$" maxLength={3} />
              </Form.Item>
              <Form.Item name="percentOff" label="Discount Percent">
                <Input placeholder="Discount Percent" prefix="%" maxLength={2} />
              </Form.Item>
              <Form.Item name="expiryDate" label="Expiry Date">
                <DatePicker format={DAY_DATE_FORMAT} />
              </Form.Item>
              <Form.Item name="active" label="Status" valuePropName="checked">
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
              <Form.Item label="Select a Client (optional)" name="clientId">
                <Select defaultValue={""}>
                  <Select.Option value="">Choose a type</Select.Option>
                  {clients.map((client, index) => (
                    <Select.Option key={index + 1} value={client.id}>
                      {client.clientName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PromoCodeEditForm;
