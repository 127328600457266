import ForgotPasswordPage from "modules/auth/pages/ForgotPasswordPage";
import LoginPage from "modules/auth/pages/LoginPage";
import RegisterPage from "modules/auth/pages/RegisterPage";
import ConfirmRoutePage from "modules/client/active-orders/pages/ConfirmRoutePage";
import ClientCreateOrderPage from "modules/client/active-orders/pages/ClientCreateOrderPage";
import JobDetail from "modules/client/active-orders/pages/JobDetail";
import AddJob from "modules/client/active-orders/pages/AddJob";
import OrderRouteDetailPage from "modules/client/active-orders/pages/OrderRouteDetailPage";
import DriverCreatePasswordPage from "modules/client/drivers/pages/DriverCreatePasswordPage";
import DriveRegisterSuccessPage from "modules/client/drivers/pages/DriveRegisterSuccessPage";
import DriverRegisterPage from "modules/client/drivers/pages/DriverRegisterPage";
import RouteHistoryListPage from "modules/client/history/pages/RouteHistoryListPage";
import InternalDriverListPage from "modules/client/internal-drivers/pages/InternalDriverListPage";
import RouteDetailPage from "modules/client/routes/pages/RouteDetailPage";
import RouteDispatchPage from "modules/client/routes/pages/RouteDispatchPage";
import RouteListPage from "modules/client/routes/pages/RouteListPage";
import SettingsPage from "modules/client/settings/pages/SettingsPage";
import APIKeyPage from "modules/client/profile/pages/APIKey";
import EditStopPage from "modules/admin/orders/pages/EditStop";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateUserRoute from "routes/PrivateUserRoute";
import OnGoingPage from "../../modules/client/on-going/pages/OnGoingPage";
import ValidationPage from "../../modules/client/validations/ValidationSection";
import DashboardPage from "../../modules/client/dashboard";
import DriverFeedbackPage from "modules/client/driver-feedback/pages/DriverFeedback";
import InternalDriverFeedbacksPage from "modules/client/driver-feedback/pages/InternalDriverFeedbacksPage";
import ClientUserListPage from "modules/client/client-users/pages/ClientUserListPage";
import WelcomePage from "modules/auth/pages/WelcomePage";
import FlexioPoolList from "modules/client/pool/pages/FlexioPoolList";
import { Alert, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectRole } from "modules/auth/store/authSlice";
import { UserRole } from "configs/constants";
import CreatePoolOrderPage from "modules/shared/CreatePoolOrderPage";
import Locations from "modules/client/settings/pages/Locations";
import ResellerDashboardPage from "modules/client/reseller-dashboard";
import HomePage from "modules/client/home/pages/HomePage";
import NewRouteServiceSelection from "modules/client/routes/pages/NewRouteServiceSelection";
import PoolTracking from "modules/client/on-going/pages/PoolTracking";
import ImportRoute from "modules/client/import-route/pages/ImportRoute";

const ClientSpace = () => {
  const role = useSelector(selectRole);
  return (
    <Switch>
      <Route exact path="/s/:token" component={OnGoingPage} />
      <Route exact path="/tracking/:token" component={PoolTracking} />
      <Route exact path="/s/dfb/:jobId" component={DriverFeedbackPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/welcome" component={WelcomePage} />
      <Route
        exact
        path="/drivers/register"
        render={() => {
          window.location.replace("/driver/register");
          return null;
        }}
      />
      <Route
        exact
        path="/drivers/register/success"
        component={DriveRegisterSuccessPage}
      />
      <Route exact path="/drivers/create-password" component={DriverCreatePasswordPage} />
      <Route
        exact
        path="/paymentMethodAdded"
        component={() => {
          setTimeout(() => {
            window.open("/settings", "_self");
          }, 5000);
          return (
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                <Alert
                  message="Success"
                  description="Payment method added successfully. You will be redirected..."
                  type="success"
                  className="mt-4"
                  showIcon
                />
              </Col>
              <Col span={8}></Col>
            </Row>
          );
        }}
      />
      <Route
        exact
        path="/paymentMethodEdited"
        component={() => {
          setTimeout(() => {
            window.open("/settings", "_self");
          }, 5000);
          return (
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                <Alert
                  message="Success"
                  description="Payment method edited successfully. You will be redirected..."
                  type="success"
                  className="mt-4"
                  showIcon
                />
              </Col>
              <Col span={8}></Col>
            </Row>
          );
        }}
      />
      <PrivateUserRoute exact path="/locations" component={Locations} />
      <PrivateUserRoute exact path="/history" component={RouteHistoryListPage} />
      <PrivateUserRoute exact path="/routes" component={RouteListPage} />
      <PrivateUserRoute exact path="/routes/:id" component={RouteDetailPage} />
      <PrivateUserRoute exact path="/routes/:id/dispatch" component={RouteDispatchPage} />
      <PrivateUserRoute exact path="/routes/:id/:eid" component={JobDetail} />
      <PrivateUserRoute exact path="/routes/:id/:eid/add-job" component={AddJob} />
      <PrivateUserRoute
        exact
        path="/routes/:routeId/stops/:id/edit"
        component={EditStopPage}
      />
      <PrivateUserRoute exact path="/orders/new" component={ClientCreateOrderPage} />
      <PrivateUserRoute exact path="/pool-orders/new" component={CreatePoolOrderPage} />
      <PrivateUserRoute exact path="/orders/confirm-route" component={ConfirmRoutePage} />
      <PrivateUserRoute exact path="/locations" component={Locations} />
      {/* <PrivateUserRoute exact path="/orders" component={OrderList} /> */}
      <PrivateUserRoute
        exact
        path="/orders/:id/routes"
        component={OrderRouteDetailPage}
      />
      <PrivateUserRoute exact path="/drivers" component={InternalDriverListPage} />
      <PrivateUserRoute path="/settings" component={SettingsPage} />
      <PrivateUserRoute exact path="/validations" component={ValidationPage} />
      <PrivateUserRoute exact path="/api-key" component={APIKeyPage} />
      <PrivateUserRoute exact path="/dashboard" component={DashboardPage} />
      <PrivateUserRoute exact path="/home" component={HomePage} />
      <PrivateUserRoute exact path="/route/new" component={NewRouteServiceSelection} />
      <PrivateUserRoute
        exact
        path="/reseller/dashboard"
        component={ResellerDashboardPage}
      />
      <PrivateUserRoute exact path="/feedbacks" component={InternalDriverFeedbacksPage} />
      {role === UserRole.ADMIN && (
        <PrivateUserRoute exact path="/client-users" component={ClientUserListPage} />
      )}
      <PrivateUserRoute exact path="/pool" component={FlexioPoolList} />
      <PrivateUserRoute exact path="/import-route" component={ImportRoute} />
    </Switch>
  );
};
export default ClientSpace;
