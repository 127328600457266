import React, { useState } from "react";
import "./ImportRoute.scss";
import { Card } from "antd";
import ImportLocations from "modules/client/routes/components/exports";

const ImportRoute = () => {
  const [, handleCloseModal] = useState(false);
  return (
    <>
      <div className="mt-3 mb-3">
        <h2 className="title">Import Route</h2>
      </div>
      <Card>
        <ImportLocations handleCloseModal={() => handleCloseModal(false)} />
      </Card>
    </>
  );
};

export default ImportRoute;
