import React, { useContext, useMemo } from "react";
import "../Home.scss";
import HomeCard from "../components/HomeCard";
import { RoutesIcon } from "components/svgs/menu/RoutesIcon";
import { ProfileContext } from "layouts/private/ProfileContext";
import { Input } from "antd";

const homeCardData = [
  {
    title: (
      <>
        Create <br />
        Routes
      </>
    ),
    description: "Enhance delivery efficiency with AI-backed route planning.",
    buttonText: "Start Planning",
    buttonLink: "route/new"
  },
  {
    title: (
      <>
        Manage <br />
        Deliveries
      </>
    ),
    description: "Oversee and adjust your delivery operations in real-time.",
    buttonText: "Access Dashboard",
    buttonLink: "routes"
  },
  {
    title: (
      <>
        Analyze <br />
        Performance
      </>
    ),
    description: "Gain insights into delivery metrics to improve operations.",
    buttonText: "Review Reports",
    buttonLink: "dashboard"
  },
  {
    title: (
      <>
        Manage My <br />
        Fleet
      </>
    ),
    description: "Add or edit your Internal Fleet.",
    buttonText: "Manage",
    buttonLink: "drivers"
  },
  {
    title: (
      <>
        Manage <br />
        Validations
      </>
    ),
    description: "Configure the Pickup and Delivery Requirements for Drivers.",
    buttonText: "Configure",
    buttonLink: "settings/validations"
  },
  {
    icon: <RoutesIcon width={40} height={40} fill="#00E2DC" />,
    title: (
      <>
        Upgrade <br />
        Your Plan
      </>
    ),
    description:
      "Unlock advanced features to further streamline your delivery operations.",
    buttonText: "Upgrade Now",
    buttonLink: "settings/subscriptions"
  }
];

const HomePage = () => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile);

  return (
    <div className="">
      <div className="home-wrapper">
        <div className="greetings-header">
          <div>
            <p className="user-greetings">
              Hello {profile?.firstName} {profile?.lastName} &#128075;,
            </p>
            <h2 className="sub-greetings">What do you want to do today?</h2>
          </div>
          {/* <div>
            <Input.Search
              placeholder="Routes, Settings..."
              autoComplete
              allowClear
              enterButton="Search"
            />
          </div> */}
        </div>
        <div className="card-container">
          {homeCardData.map((cardData, index) => {
            return (
              <HomeCard
                key={index + 1}
                icon={cardData.icon}
                title={cardData.title}
                description={cardData.description}
                buttonText={cardData.buttonText}
                buttonLink={cardData.buttonLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
