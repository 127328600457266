import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import { Button, notification, Tooltip, Card } from "antd";
import { map, sortBy, trim, findIndex } from "lodash";

import { saveAsDraftNewOrder } from "modules/client/active-orders/store/orderSlice";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { resetNewOrderStatuses } from "modules/client/active-orders/store/orderSlice";
import { QuestionIcon } from "components/svgs";
import { setGlobalLoading } from "app/global/slice";
import { generateId, replaceElementInArray } from "helper/util";

import "./index.scss";
import MapImportPreview from "../maps/MapImportPreview";

const COLUMN_CANNOT_NULL = [
  "Id",
  "Stop Type",
  "Pickup Id",
  "Stop Name",
  "Stop Address",
  "Stop Time Window - Start",
  "Stop Time Window - End",
  "Waiting/Service Time"
];
const PHONE_COLUMNS = ["Contact Phone", "Contact Phone 1", "Contact Phone 2"];
const LAT_LNG_DATA = ["Stop Latitude", "Stop Longitude"];
const PACKAGE_DATA = {
  "Total Micro Packages": "Micro",
  "Total Small Packages": "Small",
  "Total Medium Packages": "Medium",
  "Total Large Packages": "Large",
  "Total Pallet Packages": "Pallet",
  "Total Other Packages": "Custom"
};
const TIME_KEYS = ["Stop Time Window - Start", "Stop Time Window - End"];
const MAP_CSV_DATA = {
  Id: "id",
  "Stop Type": "type",
  "Contact Name": "contact_name",
  "Contact Email": "email",
  Notes: "note",
  "Stop Name": "location_name",
  "Stop Address": "physical_address",
  "Stop Time Window - Start": "from_time",
  "Stop Time Window - End": "to_time",
  "Waiting/Service Time": "duration",
  "Internal Route ID": "internalRouteId",
  "Internal Order ID": "internalOrderId",
  "Internal Customer ID": "internalCustomerId",
  Salesperson: "salesPerson",
  COD: "cod"
};
const COLUMN_NAME_BARCODE_PACKAGE = (packageName) =>
  `Barcode Number ${packageName} Packages`;
const ID_INDEX = "Id";

const ImportLocations = ({
  handleCloseModal,
  isAdmin = false,
  setTotalStop,
  setCurrentStop
}) => {
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const packageTypeList = useSelector(selectAllPackageTypes);
  const [array, setArray] = useState([]);
  const fileInputRef = useRef();

  const onImportExcel = (tmpFile) => {
    //  gets the uploaded file object
    const { files } = tmpFile.target;

    //  the file is read through the filereader object
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });

        let data = [];
        for (const sheet in workbook.Sheets) {
          // esline-disable-next-line
          if (workbook?.Sheets) {
            //  using sheet_to_json  method will be excel  into json  data
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            const tmpData = sortBy(data, function (o) {
              return o["Stop Type"] !== "Pickup";
            });
            const [resultArr, isError] = checkErrors(tmpData);
            setError(isError);
            setArray(resultArr);
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    };

    //  open the file in binary mode
    fileReader.readAsBinaryString(files[0]);
  };
  const getPickupIds = (item) => {
    if (item["Stop Type"] === "Pickup") {
      return item["Id"];
    }
  };

  const caseError = (item, pickupIds, arr) => {
    let tpmItem = { ...item };
    let isError = false;
    if (tpmItem["Stop Type"].toLowerCase() === "dropoff") {
      if (tpmItem["Pickup Id"] && !pickupIds.includes(tpmItem["Pickup Id"])) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, "Pickup Id does not exist"]
            : ["Pickup Id does not exist"]
        };
      }
    }
    TIME_KEYS.forEach((column) => {
      const timeString = tpmItem[column].replace(":", "");
      tpmItem[column] = moment(timeString, "hmm").format("HH:mm");
      const isValid = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(
        tpmItem[column]
      );
      if (!isValid) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is invalid`]
            : [`${column} is invalid`]
        };
      }
    });
    PHONE_COLUMNS.forEach((column) => {
      if (tpmItem[column] === undefined) return;
      if (!/^[0-9()-*#+ -]+$/.test(trim(tpmItem[column]))) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} is invalid`]
            : [`${column} is invalid`]
        };
      }
    });
    COLUMN_CANNOT_NULL.forEach((column) => {
      if (!item[column]) {
        isError = true;
        tpmItem = {
          ...tpmItem,
          errors: tpmItem.errors
            ? [...tpmItem.errors, `${column} does not exist`]
            : [`${column} does not exist`]
        };
      }
    });
    const importedPackages = Object.keys(PACKAGE_DATA).filter((column) => item[column]);
    if (!importedPackages?.length) {
      isError = true;
      tpmItem = {
        ...tpmItem,
        errors: tpmItem.errors
          ? [...tpmItem.errors, `At least 1 package`]
          : [`At least 1 package`]
      };
    }

    /* Checks if stops with the same ID have the same stop address */
    arr.map((stop) => {
      // Compare current item with other stops to find others with the same ID
      if (tpmItem["Id"] === stop["Id"]) {
        // If there's an ID match but addresses aren't the same, add the error
        if (tpmItem["Stop Address"] !== stop["Stop Address"]) {
          isError = true;
          tpmItem = {
            ...tpmItem,
            errors: tpmItem.errors
              ? [
                  ...tpmItem.errors,
                  ` Stops with the ID #${tpmItem["Id"]} have different stop addresses`
                ]
              : [`Stops with the ID #${tpmItem["Id"]} have different stop addresses`]
          };
        }
      }
    });
    return [tpmItem, isError];
  };

  const checkErrors = (arr) => {
    // Validation for Package Counts by size - allow null / NaN but coerce to 0.
    let countOfDropoffPackagesMicro: number | null | undefined = 0;
    let countOfDropoffPackagesSmall: number | null | undefined = 0;
    let countOfDropoffPackagesMedium: number | null | undefined = 0;
    let countOfDropoffPackagesLarge: number | null | undefined = 0;
    let countOfDropoffPackagesPallet: number | null | undefined = 0;

    let countOfPickupPackagesMicro: number | null | undefined = 0;
    let countOfPickupPackagesSmall: number | null | undefined = 0;
    let countOfPickupPackagesMedium: number | null | undefined = 0;
    let countOfPickupPackagesLarge: number | null | undefined = 0;
    let countOfPickupPackagesPallet: number | null | undefined = 0;

    let latestDropoffTimeEnd = handleTimeImport("00:00");
    let earliestPickupTimeStart = handleTimeImport("23:59");

    let isError = false;
    let tmpArr = [...arr];
    const pickupIds = map(tmpArr, getPickupIds).filter((n) => n);
    tmpArr = tmpArr.map((ar) => {
      const [item, err] = caseError(ar, pickupIds, tmpArr);
      isError = isError || err;

      if (ar["Stop Type"].toLowerCase() === "pickup") {
        countOfPickupPackagesMicro += ar["Total Micro Packages"] ?? 0;
        countOfPickupPackagesSmall += ar["Total Small Packages"] ?? 0;
        countOfPickupPackagesMedium += ar["Total Medium Packages"] ?? 0;
        countOfPickupPackagesLarge += ar["Total Large Packages"] ?? 0;
        countOfPickupPackagesPallet += ar["Total Pallet Packages"] ?? 0;

        if (handleTimeImport(ar["Stop Time Window - Start"]) < earliestPickupTimeStart) {
          earliestPickupTimeStart = handleTimeImport(ar["Stop Time Window - Start"]);
        }
      } else if (ar["Stop Type"].toLowerCase() === "dropoff") {
        countOfDropoffPackagesMicro += ar["Total Micro Packages"] ?? 0;
        countOfDropoffPackagesSmall += ar["Total Small Packages"] ?? 0;
        countOfDropoffPackagesMedium += ar["Total Medium Packages"] ?? 0;
        countOfDropoffPackagesLarge += ar["Total Large Packages"] ?? 0;
        countOfDropoffPackagesPallet += ar["Total Pallet Packages"] ?? 0;

        if (handleTimeImport(ar["Stop Time Window - End"]) > latestDropoffTimeEnd) {
          latestDropoffTimeEnd = handleTimeImport(ar["Stop Time Window - End"]);
        }
      }
      return item;
    });

    if (earliestPickupTimeStart >= latestDropoffTimeEnd) {
      isError = true;
      notification.error({
        message: "Validation error - Pickup Window is later than Dropoff Window.",
        placement: "topRight"
      });
    }

    // Compare for each size and throw specific error
    if (countOfDropoffPackagesMicro !== countOfPickupPackagesMicro) {
      isError = true;
      notification.error({
        message:
          "Validation error - Micro Packages count is incorrect. Please double check the pickup and dropoff counts for total number of micro packages.",
        placement: "topRight"
      });
    }

    if (countOfDropoffPackagesSmall !== countOfPickupPackagesSmall) {
      isError = true;
      notification.error({
        message:
          "Validation error - Small Packages count is incorrect. Please double check the pickup and dropoff counts for total number of small packages.",
        placement: "topRight"
      });
    }

    if (countOfDropoffPackagesMedium !== countOfPickupPackagesMedium) {
      isError = true;
      notification.error({
        message:
          "Validation error - Medium Packages count is incorrect. Please double check the pickup and dropoff counts for total number of medium packages.",
        placement: "topRight"
      });
    }

    if (countOfDropoffPackagesLarge !== countOfPickupPackagesLarge) {
      isError = true;
      notification.error({
        message:
          "Validation error - Large Packages count is incorrect. Please double check the pickup and dropoff counts for total number of large packages.",
        placement: "topRight"
      });
    }

    if (countOfDropoffPackagesPallet !== countOfPickupPackagesPallet) {
      isError = true;
      notification.error({
        message:
          "Validation error - Pallet Packages count is incorrect. Please double check the pickup and dropoff counts for total number of pallet packages.",
        placement: "topRight"
      });
    }
    return [tmpArr, isError];
  };

  const handleOnChangeImport = (e) => {
    onImportExcel(e);
  };

  const clickChooseFile = (e) => {
    fileInputRef.current.click();
  };

  const handleTimeImport = (time) => {
    // return moment(XLSX.SSF.parse_date_code(time)).subtract(1, "minutes");
    return moment(time, "HH:mm");
  };

  const handleConvertTime = (time) => {
    return moment(XLSX.SSF.parse_date_code(time)).subtract(1, "minutes").format("HH:mm");
  };

  const handleImport = async () => {
    dispatch(setGlobalLoading({ isLoading: true }));
    handleCloseModal(false);
    const pickups = [];
    const dropoffs = [];
    let packages = [];
    let places = { places: {} };
    array.forEach((item) => {
      const addr = item["Stop Address"];
      places.places[addr] = { address: addr };
      let latitude = Number(item[LAT_LNG_DATA[0]]);
      let longitude = Number(item[LAT_LNG_DATA[1]]);
      if (latitude && longitude) {
        places.places[addr]["latitude"] = latitude;
        places.places[addr]["longitude"] = longitude;
      }
    });
    const response = await axios.post(window.REACT_APP_GEOCODE_API_URI, places);
    setTotalStop?.(array.length);
    for (let i = 0; i < array.length; i++) {
      setCurrentStop?.(i + 1);
      const item = array[i];
      const id = item["Id"];
      let location = {};
      const keys = Object.keys(item);
      const addr = item["Stop Address"];
      const place_response = response?.data?.places?.[addr];
      const { latitude, longitude } = place_response;
      let address = {
        address: item["Stop Address"],
        lngLat: [longitude || 0, latitude || 0]
      };
      keys.forEach((key) => {
        if (MAP_CSV_DATA[key]) {
          location = {
            ...location,
            [MAP_CSV_DATA[key]]: item[key]
          };
        }
      });
      let phonNumbers = [
        item["Contact Phone"],
        item["Contact Phone 1"],
        item["Contact Phone 2"]
      ].filter((n) => n);
      const phone_numbers = phonNumbers.map((phone) => {
        let trimmedPhone = trim(phone);
        if (trimmedPhone.match(/^(787)|^(939)/)) {
          trimmedPhone = "+1" + trimmedPhone;
        }
        return {
          type: "contact",
          phone: (trimmedPhone?.indexOf("+") === -1 ? "+" : "") + trimmedPhone
        };
      });

      const packageName = item["Item Name"];
      if (item["Stop Type"].toLowerCase() === "pickup") {
        Object.keys(PACKAGE_DATA).forEach((packageKey) => {
          if (item[packageKey]) {
            const packageId = generateId("PickupPack");
            const genericName = PACKAGE_DATA[packageKey];
            const packageType = packageTypeList.find((pack) => pack.name === genericName);

            packages.push({
              id: packageId,
              name: packageName || genericName,
              action: null,
              quantity: item[packageKey],
              packageTypeId: packageType.id,
              maxLength: packageType.maxLength,
              maxWeight: packageType.maxWeight,
              maxVolume: packageType.maxVolume,
              pickupId: id,
              barcode: item[COLUMN_NAME_BARCODE_PACKAGE(genericName)]
            });
          }
        });

        if (findIndex(pickups, { id }) !== -1) continue;
        pickups.push({
          ...location,
          id,
          address,
          from_time: handleTimeImport(item["Stop Time Window - Start"]),
          to_time: handleTimeImport(item["Stop Time Window - End"]),
          phone_numbers,
          lat: latitude,
          lng: longitude
        });
      } else {
        Object.keys(PACKAGE_DATA).forEach((packageKey) => {
          //FIXME: calculate total quantity late
          const packagePickup = packages.find(
            (pack) =>
              pack.pickupId === item["Pickup Id"] &&
              pack.name === (packageName || PACKAGE_DATA[packageKey])
          );
          if (item[packageKey]) {
            const packageWithDropoff = {
              ...packagePickup,
              dropoffs:
                packagePickup?.dropoffs?.length > 0
                  ? [
                      ...packagePickup?.dropoffs,
                      {
                        id,
                        quantity: item[packageKey]
                      }
                    ]
                  : [
                      {
                        id,
                        quantity: item[packageKey]
                      }
                    ]
            };
            packages = replaceElementInArray(packages, packageWithDropoff);
          }
        });
        if (findIndex(dropoffs, { id }) !== -1) continue;
        dropoffs.push({
          ...location,
          id,
          address,
          from_time: handleTimeImport(item["Stop Time Window - Start"]),
          to_time: handleTimeImport(item["Stop Time Window - End"]),
          phone_numbers,
          lat: latitude,
          lng: longitude
        });
      }
    }

    dispatch(
      saveAsDraftNewOrder({
        data: {
          pickups,
          dropoffs,
          packages
        }
      })
    );
    dispatch(resetNewOrderStatuses());
    notification.success({
      message: "Import successfully!",
      placement: "topRight"
    });

    history.push(isAdmin ? "/admin/jobs/new" : "orders/new");
    handleCloseModal(false);
  };

  const headerKeys = Object.keys(Object.assign({}, ...array)).filter(
    (k) => k !== "errors"
  );
  return (
    <>
      <div className="import-locations-component pb-4">
        <input
          ref={fileInputRef}
          type={"file"}
          id={"csvFileInput"}
          accept={".xlsx, .xls"}
          onChange={handleOnChangeImport}
          className="hidden file-import"
        />
        <div className="m-2">
          <div className="d-flex flex-row gap-2 ant-space-align-center">
            <Button
              className="btn-download svg-icon mb-3"
              size="small"
              onClick={clickChooseFile}
            >
              Choose file
            </Button>
            <Tooltip
              placement="right"
              overlayStyle={{ maxWidth: "600px" }}
              title={
                <span>
                  Click here to import your own <b>.xlsx</b> file and add routes in bulk.
                </span>
              }
            >
              <div className="d-flex mb-3 ant-space-align-center">
                <QuestionIcon />
              </div>
            </Tooltip>
          </div>
          <div className="download-text mb-3">
            Download Sample File:{" "}
            <a
              target="_blank"
              href="https://flexio-media.s3.amazonaws.com/sample-xlsx/sample.xlsx"
              rel="noopener noreferrer"
            >
              sample.xlsx
            </a>
          </div>
          <Card bodyStyle={{ backgroundColor: "#f2f2f2" }}>
            <p>
              The following describes the requirements for importing an <b>.xlsx</b> file:
            </p>
            <p>Mandatory fields:</p>
            <ul>
              <li>Id</li>
              <li>Stop Type</li>
              <li>
                Pickup Id (must correspond to the Pickup id in case of dropoffs and - in
                case of being a pickup)
              </li>
              <li>Contact Phone 1</li>
              <li>Stop Address</li>
              <li>Stop Time Window - Start</li>
              <li>Stop Time Window - End</li>
              <li>Waiting/Service Time</li>
            </ul>
            <p>
              Additionally, it is necessary to provide one of the following data, which
              will depend on the package type:
            </p>
            <ul>
              <li>Total Micro Packages</li>
              <li>Total Small Packages</li>
              <li>Total Medium Packages</li>
              <li>Total Large Packages</li>
              <li>Total Pallet Packages</li>
            </ul>
          </Card>

          <table id="client-table">
            <thead>
              <tr key={"header"}>
                {headerKeys.map((key) => (
                  <th key={key} className="px-3 header-table">
                    {key}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {array.map((item) =>
                item.errors ? (
                  <tr key={item[ID_INDEX]} className={item.errors && "errors"}>
                    {headerKeys.map((key) => (
                      <Tooltip placement="top" title={String(item.errors)} key={key}>
                        <td className="px-3">{item[key]}</td>
                      </Tooltip>
                    ))}
                  </tr>
                ) : (
                  <tr key={item[ID_INDEX]}>
                    {headerKeys.map((key) => (
                      <td className="px-3" key={key}>
                        {item[key]}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {!error && array?.length > 0 && (
          <div className="d-flex flex-justify-end">
            <Button
              type="primary"
              className="btn-download svg-icon mb-3 mr-2"
              onClick={handleImport}
            >
              Import
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ImportLocations;
