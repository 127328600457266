import React, { useCallback, useEffect, useState } from "react";
import request from "helper/request";
import PromoCodeList from "../components/PromoCodeList";
import { Button, DatePicker, Form, Input, Modal, Select, Spin, message } from "antd";
import { PercentageOutlined, PlusOutlined } from "@ant-design/icons";
import PromoCodeFilter from "../components/PromoCodeFilter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAllClients } from "modules/admin/clients/store/clientSlice";
import { getClientList } from "modules/admin/clients/store/thunks";

const PromoCode = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [promoType, setPromoType] = useState("");
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const clients = useSelector(selectAllClients);

  useEffect(() => {
    getAllCodes();
    dispatch(getClientList({}));
  }, []);

  const getAllCodes = useCallback(async () => {
    try {
      setLoading(true);
      const codes = await request.get("/admin/promo-code");

      if (!codes.length) {
        return;
      }

      setPromoCodes(addKeysToObj(codes));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addKeysToObj = (objArray) => {
    return objArray.map((item, index) => ({ ...item, key: index + 1 }));
  };

  const addNewPromoCode = useCallback(async (submittedData) => {
    try {
      setLoading(true);
      delete submittedData.promoType;
      if (!submittedData.clientId) {
        delete submittedData.clientId;
      }
      const formData = {
        ...submittedData,
        expiryDate: submittedData.expiryDate.format(),
        isPercent: promoType === "percent" ? true : false,
        active: true
      };
      await request.post("/admin/promo-code", formData);
      await getAllCodes();
      setShowModal(!showModal);
      form.resetFields();
      messageApi.open({
        type: "success",
        content: "Added promo code successfully!",
        duration: 5
      });
    } catch (error) {
      setShowModal(!showModal);
      form.resetFields();
      setPromoType("");
      messageApi.open({
        type: "error",
        content: error?.response?.message || "",
        duration: 5
      });
    } finally {
      setLoading(false);
    }
  });

  const deletePromoCode = useCallback(async (id) => {
    try {
      await request.delete(`/admin/promo-code/${id}`);
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <>
      {contextHolder}
      {false && (
        <PromoCodeFilter
          submitForm={() => {
            return "";
          }}
        />
      )}
      <div style={{ display: "flex", margin: "1em 0", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={() => setShowModal(!showModal)}>
          Add Promo Code
          <PlusOutlined />
        </Button>
      </div>
      <PromoCodeList
        data={promoCodes}
        getAllCodes={getAllCodes}
        loading={loading}
        deletePromoCode={deletePromoCode}
        setLoading={setLoading}
      />
      <Modal open={showModal} footer={false} onCancel={() => setShowModal(!showModal)}>
        <h3>Add Promo Code</h3>
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={addNewPromoCode}>
            <Form.Item
              label="Promo Code"
              name="discountName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Promo Code" />
            </Form.Item>
            <Form.Item
              label="Expiry Date"
              name="expiryDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker onChange={(e) => console.log(e.format())} />
            </Form.Item>
            <Form.Item label="Type of Discount" name="promoType">
              <Select defaultValue={""} onChange={(value) => setPromoType(value)}>
                <Select.Option value="">Choose a type</Select.Option>
                <Select.Option value="amount">Amount</Select.Option>
                <Select.Option value="percent">Percent</Select.Option>
              </Select>
            </Form.Item>
            {promoType && (
              <>
                {promoType === "amount" ? (
                  <Form.Item
                    label="Amount"
                    name="amountOff"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="Amount" prefix="$" maxLength={3} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Percent"
                    name="percentOff"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      placeholder="Percent"
                      prefix={<PercentageOutlined />}
                      maxLength={2}
                    />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item label="Select a Client (optional)" name="clientId">
              <Select defaultValue={""}>
                <Select.Option value="">Choose a type</Select.Option>
                {clients.map((client, index) => (
                  <Select.Option key={index + 1} value={client.id}>
                    {client.clientName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default PromoCode;
