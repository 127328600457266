import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Select } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { get } from "lodash";

import { setSuccessMessage } from "app/global/slice";
import { canCreatePermission } from "configs/constants";
import { doExportCsv } from "helper/export";
import { allowPermission, fullName } from "helper/util";
import { selectRole } from "modules/auth/store/authSlice";
import { buildFirstLevelFilter } from "modules/client/internal-drivers/helper";
import { selectAllInternalDrivers } from "modules/client/internal-drivers/store/slice";
import { getInternalDriverList } from "modules/client/internal-drivers/store/thunks";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import { buildFirstLevelFilterRoute } from "modules/shared/PickupDropoff/helper";
import paginationFunc from "../../../../helper/hooks/pagination";
import ImportLocations from "../components/exports";
import FilterRoute from "../components/header/FilterRoute";
import RoutesHeader from "../components/header/RoutesHeader";
import RouteListTable from "../components/tables/RouteListTable";
import WaitingModal from "../components/WaitingModal";
import {
  selectAllRoutes,
  selectFilter,
  selectMetadata,
  updateFilterRoutes,
  updatePaginationRoutes
} from "../store/slice";
import {
  assignDriver,
  cancelRoute,
  doDeleteRoute,
  getRouteList,
  reassignDriver
} from "../store/thunks";
import SubscriptionOverlay from "modules/shared/SubscriptionOverlay";
import { ProfileContext } from "layouts/private/ProfileContext";
import { createSubscriptionSession } from "helper/util";

const RouteListPage = () => {
  const fileInputRef = useRef();
  const [totalStop, setTotalStop] = useState(0);
  const [currentStop, setCurrentStop] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showAssignDriver, setShowAssignDriver] = useState(false);
  const [currentRoute, setCurrentRoute] = useState({});
  const [assignedDriverId, setAssignedDriverId] = useState("");
  const isLoading = useSelector((state) => state.global.isLoading);
  const breadCrumbs = [{ text: "ROUTES", url: "/" }];
  const dispatch = useDispatch();
  const orderList = useSelector(selectAllRoutes);
  const metadata = useSelector(selectMetadata);
  const filter = useSelector(selectFilter);
  const driverList = useSelector(selectAllInternalDrivers);
  const pagination = paginationFunc(
    { ...metadata, itemsPerPage: get(metadata, "itemsPerPage", 20) },
    "Active Job",
    updatePaginationRoutes,
    dispatch
  );
  const page = metadata?.currentPage || 1;
  const pageSize = metadata?.itemsPerPage || 20;
  const canCreate = allowPermission(useSelector(selectRole), canCreatePermission);
  const profileCtx = useContext(ProfileContext);
  const isSubscriptionActive = useMemo(
    () => profileCtx.isSubscriptionActive,
    [profileCtx]
  );
  const isProfileLoading = useMemo(() => profileCtx.isLoading, [profileCtx]);
  const hasUsedTrial = useMemo(() => profileCtx?.hasUsedTrial, [profileCtx.hasUsedTrial]);

  const percent = useMemo(
    () => Math.round((currentStop * 100) / totalStop),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStop]
  );

  useEffect(() => {
    dispatch(getPackageTypeList());
    dispatch(
      getInternalDriverList({
        pageSize: 1000,
        filter: buildFirstLevelFilter({ is_internal_driver: true, active: true })
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pickupDateRange = [] } = filter || {};
    dispatch(
      getRouteList({
        page: page,
        pageSize: pageSize,
        filter: buildFirstLevelFilterRoute(
          pickupDateRange?.length === 2
            ? {
                ...filter,
                startPickupDate: pickupDateRange[0],
                endPickupDate: pickupDateRange[1]
              }
            : filter
        ),
        pickupFilter: filter
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filter]);

  const handleClickFilter = (values) => {
    dispatch(updateFilterRoutes(values));
  };

  const handleDeleteOrder = (id) => {
    dispatch(doDeleteRoute(id));
  };

  const handleCancelRoute = async (routeId) => {
    try {
      const { error } = await dispatch(cancelRoute(routeId));
      if (!error) {
        dispatch(setSuccessMessage("Canceled job successfully!"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExportRoutes = async () => {
    try {
      const { pickupDateRange = [] } = filter || {};
      setExporting(true);
      await doExportCsv({
        path: "routes/export",
        name: `Active-Jobs-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilterRoute(
            pickupDateRange?.length === 2
              ? {
                  ...filter,
                  startPickupDate: pickupDateRange[0],
                  endPickupDate: pickupDateRange[1]
                }
              : filter
          ),
          pickupFilter: filter
        }
      });
    } finally {
      setExporting(false);
    }
  };
  const handleClickImport = () => {
    fileInputRef.current.click();
  };

  const handleOnChangeImport = (e) => {
    console.log("e", e.target.files[0]);
  };

  const handleCloseModalImport = () => {
    setShowImport(false);
    setShowWaitingModal(true);
  };

  const handleAssignDriverClick = (route) => {
    setCurrentRoute(route);
    setAssignedDriverId(route?.driverId);
    setShowAssignDriver(true);
  };

  const handleSubmitAssignDriver = async () => {
    if (!assignedDriverId) return;
    try {
      const { error } = await dispatch(
        currentRoute?.driverId
          ? reassignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
          : assignDriver({ routeId: currentRoute?.id, driverId: assignedDriverId })
      );
      if (!error) {
        dispatch(
          setSuccessMessage(
            `${currentRoute?.driverId ? "Reassigned" : "Assigned"} successfully!`
          )
        );
        setShowAssignDriver(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = useCallback(async (priceId) => {
    createSubscriptionSession(priceId);
  });

  return (
    <div className="client-order-list-page">
      {!isProfileLoading && isSubscriptionActive === false && (
        <SubscriptionOverlay handleSubmit={handleSubmit} hasUsedTrial={hasUsedTrial} />
      )}
      <RoutesHeader
        breadCrumbs={breadCrumbs}
        showFilter={showFilter}
        showCreateButton={canCreate}
        setShowFilter={setShowFilter}
        setShowImport={setShowImport}
        fileInputRef={fileInputRef}
        handleClickImport={handleClickImport}
        handleOnChangeImport={handleOnChangeImport}
        onExport={handleExportRoutes}
        exporting={exporting}
      />
      {showFilter && (
        <FilterRoute
          defaultValues={filter}
          setShowFilter={setShowFilter}
          handleClickFilter={handleClickFilter}
          onReset={() => dispatch(updateFilterRoutes(undefined))}
        />
      )}
      <div className="content-table">
        <RouteListTable
          doDelete={handleDeleteOrder}
          onCancelRoute={handleCancelRoute}
          onAssignDriverClick={handleAssignDriverClick}
          onReassignDriverClick={handleAssignDriverClick}
          dataSource={orderList}
          pagination={{ ...pagination, size: "small" }}
          hiddenColumns={["Min. Vehicle Type"]}
        />
      </div>
      {showWaitingModal && (
        <Modal
          closable={false}
          visible={true}
          className="no-padding-modal"
          onCancel={() => setShowWaitingModal(false)}
          footer={false}
          width={400}
        >
          <WaitingModal percent={percent} />
        </Modal>
      )}
      {showAssignDriver && (
        <Modal
          closable={false}
          visible={true}
          title={`${currentRoute?.driverId ? "Reassign" : "Assign"} Driver`}
          width={600}
          footer={
            <div className="buttons-modal">
              <Button size="large" onClick={() => setShowAssignDriver(false)}>
                Cancel
              </Button>
              <Button
                disabled={
                  !assignedDriverId || assignedDriverId === currentRoute?.driverId
                }
                loading={isLoading}
                size="large"
                type="primary"
                onClick={handleSubmitAssignDriver}
              >
                Assign
                <CheckCircleOutlined />
              </Button>
            </div>
          }
        >
          <Select
            size="large"
            placeholder="Select driver"
            onChange={(driverId) => setAssignedDriverId(driverId)}
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            value={assignedDriverId}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {driverList.map((driver) => (
              <Select.Option key={driver.id} value={driver.id}>
                {fullName(driver)}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      )}
    </div>
  );
};

export default RouteListPage;
