/* eslint-disable react/display-name */
import React, { useState } from "react";
import TableBase from "../../../../components/table/TableBase";
import moment from "moment";
import { DATETIME_FORMAT_FRONTEND } from "configs/constants";
import PoolStatus from "components/items/PoolStatus";
import { convertEmergencyStatus } from "helper/util";
import "modules/admin/orders/styles/routes.scss";
import { Checkbox, Tooltip, message, Button } from "antd";

// for button for Print Packing Slip
import generatePdfPackingSlipPool from "helper/generatePdfPackingSlipPool";
import { PrintPDFIcon } from "components/svgs";

const FlexioPoolTable = ({
  dataSource,
  pagination,
  hiddenColumns = [],
  setSelectedPackage,
  setShowUpdateStatus,
  selectAll,
  handlePackageArray,
  ...props
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  // Handles the "loading" state for Printing (grey out until done)
  const [printing, setPrinting] = useState(false);

  // This function gets details and creates the PDF packing slip(s).
  // Data "dataSource" is coming from "PackageList" in FlexioPoolList.jsx; e.g. array of 20 packages.
  const handlePrintPackingSlipPool = async (packageId) => {
    // The logic that was here in the Shift version, was just preparatory for passing to generatePdfPackingSlip. No need to pre-manage the Flexio Pool Packages.

    try {
      // grey out screen for loading
      setPrinting(true);

      // calls helper/generatePdfPackingSlipPool with both datasouce (array of 20 / filtered) and distinct package ID for printing single
      const doc = await generatePdfPackingSlipPool(dataSource, packageId);
      const filename = `Flexio-Pool-` + packageId?.substr(0, 8)?.toUpperCase() + `.pdf`;
      doc?.save(filename);
    } finally {
      // un-grey out screen for loading
      setPrinting(false);
    }
  };

  const columns = [
    /* The "Select" column only shows up in the batch update modal,
    used to select a few packages for the status update*/
    {
      title: "Select",
      fixed: "left",
      width: 50,
      render: (value) => {
        return (
          <Checkbox
            onChange={() => handlePackageArray(value?.id)}
            disabled={selectAll ? true : false}
          ></Checkbox>
        );
      }
    },
    {
      title: "Client",
      width: 100,
      dataIndex: "orderV2",
      render: (value) => {
        return <p style={{ margin: 0, color: "#1f3998" }}> {value.client.clientName} </p>;
      }
    },
    {
      title: "Package ID",
      dataIndex: "id",
      fixed: "left",
      width: 70,
      render: (value) => {
        return (
          <Tooltip placement="top" title="Copy">
            <p
              style={{ margin: 0, color: "#1f3998" }}
              onClick={() => {
                navigator.clipboard.writeText(value);
                messageApi.info("Package ID copied.");
              }}
            >
              {value.substr(0, 8).toUpperCase()}
            </p>
          </Tooltip>
        );
      }
    },
    {
      title: "Order ID",
      width: 70,
      dataIndex: "orderV2_id",
      render: (value) => {
        return (
          <p style={{ margin: 0, color: "#1f3998" }}>
            {value.substr(0, 8).toUpperCase()}
          </p>
        );
      }
    },
    /* Package data is selected and modal shows up when clicking on a package status from the table */
    {
      title: "Status",
      width: 80,
      render: (record) => (
        <PoolStatus
          value={convertEmergencyStatus(record)}
          record={record}
          setSelectedPackage={setSelectedPackage}
          setShowUpdateStatus={setShowUpdateStatus}
          isAdmin={true}
        />
      )
    },
    {
      title: "Packing Slip",
      width: 110,
      // must be 110 minimum to show icon correctly
      // this required change to TableBase.jsx to remove scroll x 100%
      minWidth: 110,
      fixed: "left",
      render: (record) => {
        // destructure to get record.id:
        const { id } = record;
        return (
          <Button
            size="small"
            type="third"
            className={`svg-icon d-inline-flex flex-row`}
            onClick={() => handlePrintPackingSlipPool(id)}
            loading={printing}
          >
            Print Packing Slip <PrintPDFIcon />
          </Button>
        );
      }
    },
    {
      title: "Pickup Address",
      width: 130,
      render: (record) => {
        return record?.pickupAddress || "N/A";
      }
    },
    {
      title: "Dropoff Stop Name",
      width: 150,
      render: (record) => {
        return record?.dropoffStopName || "N/A";
      }
    },
    {
      title: "Dropoff Address",
      width: 130,
      render: (record) => {
        return record?.dropoffAddress || "N/A";
      }
    },
    {
      title: "Dropoff Contact Name",
      width: 150,
      render: (record) => {
        return record?.dropoffContactName || "N/A";
      }
    },
    {
      title: "Dropoff Phone Number",
      width: 150,
      render: (record) => {
        return record?.dropoffContactPhone || "N/A";
      }
    },
    {
      title: "Pickup Date",
      width: 110,
      dataIndex: "pickupDate",
      render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
    },
    {
      title: "Created At",
      width: 110,
      dataIndex: "createdAt",
      render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
    },
    {
      title: "Last Updated At",
      width: 110,
      dataIndex: "updatedAt",
      render: (value) => moment(value).format(DATETIME_FORMAT_FRONTEND)
    }
  ];
  const filteredColumns =
    hiddenColumns.length > 0
      ? columns.filter((col) => !hiddenColumns.includes(col.title))
      : columns;
  return (
    <>
      {contextHolder}
      <TableBase
        columns={filteredColumns}
        data={dataSource}
        pagination={pagination}
        rowKey="id"
        style={{ overflow: "auto" }}
        {...props}
      />
      {/* img required as a target for generatePdfPackingSlipPool jsBarcode to render against. It's CSS is set to display none. */}
      <img id="poolPackageBarcode" />
    </>
  );
};
export default FlexioPoolTable;
