import React, { useContext, useMemo, useState } from "react";
import { Row, Col } from "antd";
import cn from "classnames";

import NotificationSetting from "./NotificationSetting";
import "./SettingsPage.scss";
import ValidationSection from "../../validations/ValidationSection";
import ReturnProcess from "./ReturnProcessPage";
import ChangePassword from "./ChangePassword";
import MyProfilePage from "./MyProfilePage";
import PaymentMethodPage from "./PaymentMethodPage";
import InvoicingListPage from "../../invoicing/pages/InvoicingList";
import RoutingProcessPage from "./RoutingProcessPage";
import { PoolInvoices } from "./PoolInvoices";
import { FeatureFlagsContext } from "layouts/private/FeatureFlagsContext";
import SubscriptionsPage from "./SubscriptionsPage";
import { Link, Route } from "react-router-dom";

const SETTING_TYPES = {
  MY_PROFILE: "my_profile",
  INVOICING: "invoicing",
  POOL_INVOICES: "pool-invoices",
  SUBSCRIPTIONS: "subscriptions",
  NOTIFICATION: "notification",
  CHANGE_PASSWORD: "change_password",
  VALIDATIONS: "validations",
  ROUTING_PROCESS: "routing_process",
  RETURN_PROCESS: "return_process",
  PAYMENTMETHOD: "payment_method"
};

const routes = [
  {
    path: "/settings/my-profile",
    component: MyProfilePage
  },
  {
    path: "/settings/invoicing",
    component: InvoicingListPage
  },
  {
    path: "/settings/notification",
    component: NotificationSetting
  },
  {
    path: "/settings/pool-invoices",
    component: PoolInvoices
  },
  {
    path: "/settings/subscriptions",
    component: SubscriptionsPage
  },
  {
    path: "/settings/validations",
    component: ValidationSection
  },
  {
    path: "/settings/routing-process",
    component: RoutingProcessPage
  },
  {
    path: "/settings/return-process",
    component: ReturnProcess
  },
  {
    path: "/settings/change-password",
    component: ChangePassword
  },
  {
    path: "/settings/payment-method",
    component: PaymentMethodPage
  }
];

const SettingsPage = () => {
  const [settingType, setSettingType] = useState(SETTING_TYPES.MY_PROFILE);
  const featureCtx = useContext(FeatureFlagsContext);
  const isPoolFeatureEnable = useMemo(() => featureCtx?.isPoolAvailable, [featureCtx]);
  const isPoolInvoicesEnabled = window.POOL_INVOICING_FEATURE;

  return (
    <div className="settings-page">
      <div className="content-main">
        <Row gutter={16}>
          <Col span={4}>
            <div
              className="setting-menu"
              style={{ maxWidth: "200px", minWidth: "150px" }}
            >
              <Link
                to="/settings/my-profile"
                className={cn({ active: settingType === SETTING_TYPES.MY_PROFILE })}
                onClick={() => setSettingType(SETTING_TYPES.MY_PROFILE)}
              >
                My Profile
              </Link>
              <Link
                to="invoicing"
                className={cn({ active: settingType === SETTING_TYPES.INVOICING })}
                onClick={() => setSettingType(SETTING_TYPES.INVOICING)}
              >
                Invoicing
              </Link>
              {isPoolFeatureEnable && isPoolInvoicesEnabled && (
                <Link
                  to="pool-invoices"
                  className={cn({ active: settingType === SETTING_TYPES.POOL_INVOICES })}
                  onClick={() => setSettingType(SETTING_TYPES.POOL_INVOICES)}
                >
                  Pool Invoices
                </Link>
              )}
              <Link
                to="subscriptions"
                className={cn({ active: settingType === SETTING_TYPES.SUBSCRIPTIONS })}
                onClick={() => setSettingType(SETTING_TYPES.SUBSCRIPTIONS)}
              >
                Subscriptions
              </Link>
              <Link
                to="payment-method"
                className={cn({ active: settingType === SETTING_TYPES.PAYMENTMETHOD })}
                onClick={() => setSettingType(SETTING_TYPES.PAYMENTMETHOD)}
              >
                Payment Method
              </Link>
              <Link
                to="notification"
                className={cn({ active: settingType === SETTING_TYPES.NOTIFICATION })}
                onClick={() => setSettingType(SETTING_TYPES.NOTIFICATION)}
              >
                Notification Settings
              </Link>
              <Link
                to="validations"
                className={cn({ active: settingType === SETTING_TYPES.VALIDATIONS })}
                onClick={() => setSettingType(SETTING_TYPES.VALIDATIONS)}
              >
                Validations
              </Link>
              <Link
                to="routing-process"
                className={cn({ active: settingType === SETTING_TYPES.ROUTING_PROCESS })}
                onClick={() => setSettingType(SETTING_TYPES.ROUTING_PROCESS)}
              >
                Routing Process
              </Link>
              <Link
                to="return-process"
                className={cn({ active: settingType === SETTING_TYPES.RETURN_PROCESS })}
                onClick={() => setSettingType(SETTING_TYPES.RETURN_PROCESS)}
              >
                Return Process
              </Link>
              <Link
                to="change-password"
                className={cn({ active: settingType === SETTING_TYPES.CHANGE_PASSWORD })}
                onClick={() => setSettingType(SETTING_TYPES.CHANGE_PASSWORD)}
              >
                Change Password
              </Link>
            </div>
          </Col>
          <Col span={18}>
            <div className="setting-content">
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index + 1}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingsPage;
