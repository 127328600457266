import React, { useContext, useMemo, useEffect, useState } from "react";
import request from "helper/request";
import "./NewRouteServiceSelection.scss";
import ServiceCard from "../components/ServiceCard";
import { ProfileContext } from "layouts/private/ProfileContext";
import { FeatureFlagsContext } from "layouts/private/FeatureFlagsContext";

const NewRouteServiceSelection = ({ flexioServices }) => {
  const profileCtx = useContext(ProfileContext);
  const profile = useMemo(() => profileCtx.profile);
  const [services, setServices] = useState([]);
  const featureCtx = useContext(FeatureFlagsContext);
  const isPoolFeatureEnable = useMemo(() => featureCtx?.isPoolAvailable, [featureCtx]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await request.get(
          window.REACT_APP_API_ENDPOINT + "/services?client_id=" + profile.client.id
        );
        setServices(response);
        //console.log(response);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, [profile.client.id]);

  return (
    <div className="">
      <div className="home-wrapper">
        <div className="greetings-header">
          <div>
            <h2 className="sub-greetings">Plan Your Delivery Routes</h2>
            <p>Create, optimize, and upload routes for efficient delivery management.</p>
          </div>
        </div>
        <div className="card-container">
          {services.map((service) => (
            <ServiceCard
              key={service.id}
              title={service.name}
              description={service.description}
              bulletPointOne={service.bulletPointOne}
              bulletPointTwo={service.bulletPointTwo}
              bulletPointThree={service.bulletPointThree}
              buttonText={`Select ${service.name}`}
              buttonLink="/orders/new?action=new"
            />
          ))}
          {isPoolFeatureEnable && (
            <ServiceCard
              key={"0"}
              title={"Flexio Pool"}
              description={"Simple per-package service"}
              bulletPointOne={"Fixed Price per Package"}
              bulletPointTwo={"Deliveries to all PR"}
              bulletPointThree={"Next Day. Volume Discounts."}
              buttonText={`Select Flexio Pool`}
              buttonLink="/pool-orders/new"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewRouteServiceSelection;
