/* eslint-disable prettier/prettier */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Row, Select, Checkbox, Tabs } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { get } from "lodash";
import _keys from "lodash/keys";
import paginationFunc from "../../../../helper/hooks/pagination";
import FlexioPoolHeader from "../components/Header";
import FlexioPoolTable from "../components/FlexioPoolTable";
import FilterFlexioPool from "../components/Filter";
import PoolStatus from "components/items/PoolStatus";
import flexioPoolApi from "modules/client/pool/store/api";
import { POOL_PACKAGE_STATUSES } from "helper/status";
import { separateStatusUnderlineLetter } from "modules/shared/PickupDropoff/helper";
import { setSuccessMessage } from "app/global/slice";
import {
  selectMetadata,
  updatePaginationPackages
} from "modules/client/pool/store/slice";
import "modules/admin/orders/styles/routes.scss";
import { doExportCsv } from "helper/export";
import { refreshPackagesV2 } from "helper/getPackagesV2";
import { buildFirstLevelFilterPoolExport } from "helper/util";
import { saveAsDraftNewOrder } from "modules/client/active-orders/store/orderSlice";
import { generateId } from "helper/util";
import { useHistory } from "react-router-dom";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import moment from "moment";
import axios from "axios";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";

const breadCrumbs = [{ text: "POOL PACKAGES" }];

const { TabPane } = Tabs;

const handleTimeImport = (time) => {
  return moment(time, "HH:mm");
};

const FlexioPoolList = () => {
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const metadata = useSelector(selectMetadata);
  const [axiosPackageList, setAxiosPackageList] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [batchStatusUpdate, setBatchStatusUpdate] = useState(false);
  const [packageArray, setPackageArray] = useState([]);
  const [selectAll, setSelectAll] = useState();
  const [newStatus, setNewStatus] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [selectedPoolOrder, setSelectedPoolOrder] = useState([]);
  const packageTypeList = useSelector(selectAllPackageTypes);
  const history = useHistory();
  const pagination = useMemo(
    () =>
      paginationFunc(
        { ...metadata, itemsPerPage: metadata?.itemsPerPage || 20 },
        "Packages",
        updatePaginationPackages,
        dispatch
      ),
    [metadata, dispatch]
  );

  const myReduxState = useSelector((state) => state.myReducer);

  const page = useMemo(() => metadata?.currentPage || 1, [metadata?.currentPage]);
  const pageSize = useMemo(() => metadata?.itemsPerPage || 20, [metadata?.itemsPerPage]);

  const getPackages = useCallback(() => {
    refreshPackagesV2(
      filterValues,
      page,
      pageSize,
      setAxiosPackageList,
      updatePaginationPackages,
      dispatch
    );
  }, [filterValues, page, pageSize, dispatch]);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  useEffect(() => {
    dispatch(getPackageTypeList({ pageSize: 1000 }));
  }, [dispatch]);

  // method that handles apply button filter request
  const handleClickFilter = () => {
    getPackages();
  };
  // method that handles Clear button reset
  const handleReset = () => {
    setFilterValues({});
  };

  const handleFilterChange = (newFilterValues) => {
    setFilterValues(newFilterValues);
  };

  const onExport = async () => {
    try {
      setExporting(true);
      await doExportCsv({
        path: "orderv2/export",
        name: `pool-orders-${new Date().getTime()}`,
        params: {
          filter: buildFirstLevelFilterPoolExport(
            filterValues?.pickupDateRange?.length > 0
              ? {
                  ...filterValues,
                  startPickupDate: filterValues.pickupDateRange[0],
                  endPickupDate: filterValues.pickupDateRange[1]
                }
              : {
                  ...filterValues
                }
          )
        }
      });
    } finally {
      setExporting(false);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setSubmiting(true);

      await flexioPoolApi.updatePackageStatus(selectedPackage?.id, {
        status: newStatus
      });

      dispatch(setSuccessMessage("Updated status successfully!"));
    } catch (err) {
      console.error("Error in handlePackageUpdate:", err);
    } finally {
      setSubmiting(false);
      setNewStatus(null);
      setShowUpdateStatus(false);
      getPackages();
    }
  };

  /* Adds all the packages visible on the current page to the array for
  batch status update*/

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const selectedPackages = selectAll ? [] : axiosPackageList?.map((pack) => pack.id);
    setPackageArray(selectedPackages);
  };

  /* Adds packages to the array for batch status update one by one by clicking the select
  checkbox on the modal table */

  const handlePackageArray = (pack) => {
    const tempArr = packageArray;
    /* Checks if the package already exists on the array to remove it if we uncheck the box */
    if (tempArr.includes(pack)) {
      const index = tempArr.indexOf(pack);
      tempArr.splice(index, 1);
      setPackageArray(tempArr);
    } else {
      packageArray.push(pack);
    }
  };

  /* Maps through the ids in the package array and performs the update on each one */

  const handleBatchUpdate = async () => {
    for (const packageId of packageArray) {
      try {
        setSubmiting(true);
        await flexioPoolApi.updatePackageStatus(packageId, {
          status: newStatus
        });
      } catch (err) {
        console.error("Error in handleBatchUpdate:", err);
      }
    }
    dispatch(setSuccessMessage("Updated status successfully!"));
    setSubmiting(false);
    setShowUpdateStatus(false);
    setBatchStatusUpdate(false);
    setPackageArray([]);
    setSelectAll(false);
    getPackages();
  };

  /* State for tab change */
  const handleTabChange = (activeKey) => {
    if (activeKey === "1") {
      setShowFilter(false);
      setFilterValues({});
    } else if (activeKey === "2") {
      setShowFilter(false);
      setFilterValues({ status: "submitted" });
    } else if (activeKey === "3") {
      setShowFilter(true);
      setFilterValues({ status: "at_warehouse" });
    } else {
      setShowFilter(false);
      setFilterValues({});
    }
  };
  const handleChangePoolToShift = (poolOrders) => {
    setSelectedPoolOrder(poolOrders);
  };

  /*** HANDLE POOL TO SHIFT ***/

  /* BEFORE the function, define the Pickup and Dropoff routes */
  /* Moved Pickup and Dropoff route defs here - must be outside loop - I have not double checked this closely */
  const poolToShiftPickupRoute = (
    pickupId,
    poolPkg,
    { latitude: pickupLatitude, longitude: pickupLongitude }
  ) => {
    const pickupAddress = {
      address: poolPkg.pickupAddress,
      lngLat: [pickupLongitude || 0, pickupLatitude || 0]
    };

    return {
      type: "Pickup",
      id: pickupId,
      address: pickupAddress,
      contact_name: poolPkg.pickupContactName,
      duration: 30,
      from_time: handleTimeImport("8:00"),
      to_time: handleTimeImport("17:00"),
      lat: poolPkg.pickupLat,
      lng: poolPkg.pickupLng,
      location_name: poolPkg.pickupStopName,
      phone_numbers: [{ type: "contact", phone: poolPkg?.pickupContactPhone }],
      physical_address: poolPkg.pickupAddress
    };
  };

  const poolToShiftDropoffRoute = (
    _pickupId,
    poolPkg,
    { latitude: dropoffLatitude, longitude: dropoffLongitude }
  ) => {
    let dropoffAddress = {
      address: poolPkg.dropoffAddres,
      lngLat: [dropoffLongitude || 0, dropoffLatitude || 0]
    };
    if (poolPkg?.status === "submitted") {
      return {
        type: "Dropoff",
        // id: i + 2,
        address: {
          address: "CVFM+P7X, Guaynabo, 00962",
          lngLat: [-66.11674888895493, 18.424398627652604]
        },
        contact_name: "Day and Night Transport",
        duration: 30,
        from_time: handleTimeImport("8:00"),
        to_time: handleTimeImport("17:00"),
        lat: 18.424398627652604,
        lng: -66.11674888895493,
        location_name: "Day and Night Transport",
        phone_numbers: [{ type: "contact", phone: "+1787-123-4567" }],
        physical_address: "CVFM+P7X, Guaynabo, 00962",
        quantity: 1
      };
    }
  };

  /* Main function to convert Packages to a shift order */
  const handlePoolToShift = async () => {
    /* Allow Mix status? There are 2 types of routes we create: 1. Multiple Pickup to Warehouse,
    and 2. Warehouse to multiple dropoffs. In order to prevent mixup, prompt to confirm */

    let allSameStatus = true;
    let firstStatus = "";

    // quick loop checking each package status against first
    selectedPoolOrder.forEach((individualPackage, index) => {
      if (index === 0) {
        firstStatus = individualPackage.status;
      } else if (individualPackage.status !== firstStatus) {
        allSameStatus = false;
      }
    });

    /* AVL - get lat long for each place */

    const pickups = [];
    const dropoffs = [];
    let draftPackages = [];

    let places = { places: {} };
    selectedPoolOrder.forEach(async (item) => {
      const pickupAddr = item?.pickupAddress || "";
      const dropoffAddr = item?.dropoffAddress || "";
      places.places[pickupAddr] = { address: pickupAddr };
      places.places[dropoffAddr] = { address: dropoffAddr };

      let pickupLatitude = item.pickupLat;
      let pickupLongitude = item.pickupLng;
      let dropoffLatitude = item.dropoffLat;
      let dropoffLongitude = item.dropoffLng;
      if (pickupLatitude && pickupLongitude && dropoffLatitude && dropoffLongitude) {
        places.places[pickupAddr]["latitude"] = pickupLatitude;
        places.places[pickupAddr]["longitude"] = pickupLongitude;
        places.places[dropoffAddr]["latitude"] = dropoffLatitude;
        places.places[dropoffAddr]["longitude"] = dropoffLongitude;
      }
    });

    let pickupId = 0;
    const response = await axios.post(window.REACT_APP_GEOCODE_API_URI, places);

    /* Main loop "for each package we selected" - "poolOrder" is the individual package */
    for (let i = 0; i < selectedPoolOrder.length; i++) {
      const poolOrder = selectedPoolOrder[i];

      /* Prep data - address and duplicate check */
      const pickupPlaceResponse = response?.data?.places?.[poolOrder.pickupAddress];
      const dropoffPlaceResponse = response?.data?.places?.[poolOrder.dropoffAddress];

      const isDuplicatePickup = pickups.some(
        (pickup) => pickup.physical_address === poolOrder.pickupAddress
      );

      /* Okay, now build the pickups / dropoffs based on logic.
      This is bad and has a logic error we need to fix.
      
      if all status = submitted, for each build a ToShiftPickup
      if all status = warehouse, for each build a toShiftDropoff
      
      */
      if (poolOrder?.status === "submitted" && !isDuplicatePickup) {
        pickupId = i + 1;
        pickups.push(poolToShiftPickupRoute(pickupId, poolOrder, pickupPlaceResponse));

        if (dropoffs.length <= 0) {
          dropoffs.push(
            poolToShiftDropoffRoute(pickupId, poolOrder, dropoffPlaceResponse)
          );
        }
      }

      /* Package Types */
      const packageType = packageTypeList.find(
        (pckgType) => pckgType?.id === poolOrder.packageTypeId
      );

      /* Duplicate package check */
      const isDuplicatePackage = draftPackages.find(
        (draftPkg) => draftPkg.packageTypeId === packageType?.id
      );

      if (isDuplicatePackage) {
        const draftPackageIndex = draftPackages.findIndex(
          (item) => item.packageTypeId === packageType.id
        );

        draftPackages[draftPackageIndex] = {
          ...draftPackages[draftPackageIndex],
          quantity: draftPackages[draftPackageIndex].quantity + 1,
          dropoffs: dropoffs.map((drop) => ({
            ...drop,
            quantity: draftPackages[draftPackageIndex].quantity + 1
          }))
        };

        continue; // was continue not return but getting unsyntactic
      }

      /* Okay now we are at draft Packages push */

      draftPackages.push({
        id: generateId("PickupPack"),
        // id: poolOrder.id,
        name: packageType?.name,
        action: null,
        // maybe here to check stop aggregation
        quantity: 1,
        packageTypeId: packageType?.id,
        maxLength: packageType?.maxLength,
        maxWeight: packageType?.maxWeight,
        maxVolume: packageType?.maxVolume,
        pickupId,
        barcode: poolOrder.barcode,
        packageV2: poolOrder,
        dropoffs
      });
    }

    /* Multiple Pickup One dropoff has a problem with how we pair stops.
    We MUST use isAllPickup for this model; so set that for the Pickup > Warehouse route
    based on all status == submitted */

    // this variable will be true only if all packages are "submitted"
    const forDispatchIsAllSame = selectedPoolOrder.every(
      (individualPackage) => individualPackage.status === "submitted"
    );

    dispatch(
      saveAsDraftNewOrder({
        data: {
          clientId:
            window.REACT_APP_DD_ENV !== "prod"
              ? ""
              : "5fb257dd-5f77-4398-9bc0-360d970d6c21",
          isPoolToShift: true,
          pickups,
          dropoffs,
          packages: draftPackages,
          isAllPackage: forDispatchIsAllSame // see above
        }
      })
    );
    history.push("/admin/jobs/new");

    /* I think this was leftover */
    /*
    return {
      type: "Dropoff",
      // id: i + 2,
      address: dropoffAddress,
      contact_name: poolPkg.dropoffContactName,
      duration: 30,
      from_time: handleTimeImport("8:00"),
      to_time: handleTimeImport("17:00"),
      lat: poolPkg.dropoffLat,
      lng: poolPkg.dropoffLng,
      location_name: poolPkg.dropoffStopName,
      phone_numbers: [{ type: "contact", phone: poolPkg?.dropoffContactPhone }],
      physical_address: poolPkg.dropoffAddres,
      quantity: 1
    };
    */
  };

  return (
    <Tabs defaultActiveKey="1" type="card" onChange={handleTabChange}>
      <TabPane tab="All" key="1">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={setShowFilter}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
          handlePoolToShift={handlePoolToShift}
          canCreateShiftOrder={true}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
            rowSelection={{
              type: "checkbox",
              onChange: (_rowKeys, selectedRows) => {
                handleChangePoolToShift(selectedRows);
              }
            }}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
      <TabPane tab="Submitted" key="2">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={setShowFilter}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
          handlePoolToShift={handlePoolToShift}
          canCreateShiftOrder={true}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
            rowSelection={{
              type: "checkbox",
              onChange: (_rowKeys, selectedRows) => {
                handleChangePoolToShift(selectedRows);
              }
            }}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
      <TabPane tab="At Warehouse" key="3">
        <FlexioPoolHeader
          breadCrumbs={breadCrumbs}
          showExport={true}
          setShowFilter={setShowFilter}
          onExport={onExport}
          exporting={exporting}
          setBatchStatusUpdate={setBatchStatusUpdate}
          setShowUpdateStatus={setShowUpdateStatus}
          handlePoolToShift={handlePoolToShift}
          canCreateShiftOrder={true}
        />
        {showFilter && (
          <FilterFlexioPool
            setShowFilter={setShowFilter}
            onFilterChange={handleFilterChange}
            defaultValues={filterValues}
            handleClickFilter={handleClickFilter}
            onReset={handleReset}
          />
        )}
        <div className="admin-content">
          <FlexioPoolTable
            dataSource={axiosPackageList}
            pagination={pagination}
            setSelectedPackage={setSelectedPackage}
            setShowUpdateStatus={setShowUpdateStatus}
            hiddenColumns={["Select"]}
            rowSelection={{
              type: "checkbox",
              onChange: (_rowKeys, selectedRows) => {
                handleChangePoolToShift(selectedRows);
              }
            }}
          />
          {/* Added modal for the manual status update feature*/}
          {showUpdateStatus && (
            <Modal
              closable={false}
              open={true}
              title="Edit Package Status"
              width={"70%"}
              footer={
                <div className="buttons-modal">
                  <Button
                    size="large"
                    onClick={() => {
                      setShowUpdateStatus(false);
                      setNewStatus(null);
                      setSelectedPackage(null);
                      setPackageArray([]);
                      setSelectAll([]);
                    }}
                    loading={submiting}
                    disabled={submiting}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!newStatus || newStatus === selectedPackage?.status}
                    loading={submiting}
                    size="large"
                    type="primary"
                    onClick={!batchStatusUpdate ? handleUpdateStatus : handleBatchUpdate}
                  >
                    Update
                    <CheckCircleOutlined />
                  </Button>
                </div>
              }
            >
              <div className="d-flex flex-column gap-3 change-job-status-modal">
                {!batchStatusUpdate ? (
                  <Row className="gap-5">
                    <label>Current Status</label>
                    <div className="status">
                      <PoolStatus value={selectedPackage.status} />
                    </div>
                  </Row>
                ) : (
                  <>
                    <Row className="gap-5">
                      <label>Select Jobs</label>
                    </Row>
                    <Row className="gap-5">
                      <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
                    </Row>
                    <div className="right-block jobs">
                      <FlexioPoolTable
                        dataSource={axiosPackageList}
                        pagination={pagination}
                        hiddenColumns={["Pickup Date", "Created At", "Last Updated At"]}
                        selectAll={selectAll}
                        handlePackageArray={handlePackageArray}
                      />
                    </div>
                  </>
                )}
                <Row className="flex-column gap-2">
                  <label>New Status</label>
                  <Select
                    listHeight={350}
                    size="large"
                    placeholder="Select status"
                    onChange={(status) => setNewStatus(status)}
                    style={{ width: "100%" }}
                    value={newStatus}
                  >
                    {_keys(POOL_PACKAGE_STATUSES).map((status) => (
                      <Select.Option key={status} value={status}>
                        {separateStatusUnderlineLetter(status)}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </div>
            </Modal>
          )}
        </div>
      </TabPane>
    </Tabs>
  );
};
export default FlexioPoolList;
