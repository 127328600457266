import React, { useCallback, useEffect, useState } from "react";
import PromoCodeEditForm from "../form/PromoCodeEditForm";
import { useParams } from "react-router-dom";
import request from "helper/request";
import { Form, Spin } from "antd";
import moment from "moment";
import AdminBreadCrumb from "components/AdminBreadCrumb";

const breadCrumbs = [
  { text: "PROMO CODES", url: "/admin/pool/promo-codes" },
  { text: "EDIT PROMO CODE" }
];

const PromoCodeEdit = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPromoCodeById();
  }, []);

  const getPromoCodeById = useCallback(async () => {
    try {
      const result = await request.get(`/admin/promo-code/${params?.id}`);

      form.setFieldsValue({
        ...result,
        clientId: result.clientId || "",
        expiryDate: moment(result?.expiryDate)
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updatePromoCode = useCallback(async (data) => {
    try {
      setLoading(true);
      await request.patch(`/admin/promo-code/${params?.id}`, data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <AdminBreadCrumb breadCrumbs={breadCrumbs} />
      <Spin spinning={loading}>
        <PromoCodeEditForm
          form={form}
          updatePromoCode={updatePromoCode}
          breadCrumbs={breadCrumbs}
        />
      </Spin>
    </>
  );
};

export default PromoCodeEdit;
