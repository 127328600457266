import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "modules/auth/store/authSlice";
import {
  resetNewOrderStatuses,
  saveAsDraftNewOrder,
  selectNewOrder
} from "modules/client/active-orders/store/orderSlice";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProfileContext } from "layouts/private/ProfileContext";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { DAY_DATE_FORMAT } from "configs/constants";
import {
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  notification,
  Popconfirm,
  Switch,
  Typography
} from "antd";
import { selectAllPackageTypes } from "modules/client/package-types/store/slice";
import { selectAllFlexioServices } from "modules/client/flexio-services/store/slice";
import { ReactSVG } from "react-svg";
import { getPackageTypeList } from "modules/client/package-types/store/thunks";
import { getFlexioServiceList } from "modules/client/flexio-services/store/thunks";
import {
  filterValidRoutes,
  getJobs,
  initRoutes,
  routeEstimateDefault
} from "modules/client/active-orders/helper";
import isEmpty from "lodash/isEmpty";
import { isInternalDriverService } from "modules/client/flexio-services/helper";
import uniq from "lodash/uniq";
import sumBy from "lodash/sumBy";
import {
  convertArrayToObject,
  convertObjectToArray,
  formatDateToBackend,
  handleTitleTab,
  makeId
} from "helper/util";
import { ROUTE_ESTIMATE_TYPES } from "modules/client/active-orders/constants";
import { doCreateActiveOrder } from "modules/client/active-orders/store/thunks";
import _find from "lodash/find";
import { setSuccessMessage } from "app/global/slice";
import OrdersHeader from "modules/client/active-orders/components/header/OrdersHeader";
import MapImportPreview from "modules/client/routes/components/maps/MapImportPreview";
import SiderForm from "modules/client/active-orders/components/SiderForm";
import AddressForm from "modules/client/active-orders/components/form/AddressForm";
import PackageList from "modules/client/active-orders/components/PackageList";
import PackageListDrop from "modules/client/active-orders/components/PackageListDrop";
import ServiceStep from "modules/client/active-orders/components/steps/ServiceStep";
import { CheckCircleOutlined } from "@ant-design/icons";
import PackForm from "modules/client/active-orders/components/form/PackForm";
import SelectPack from "modules/client/active-orders/components/form/SelectPack";
import { withPermission } from "hocs/withPermission";
import { UserRole } from "configs/constants";
import ImportLocations from "modules/client/routes/components/exports";
import { ImportIcon } from "components/svgs/ImportIcon";

const { Panel } = Collapse;

const { Paragraph } = Typography;

const routesInit = initRoutes();

const init = {
  id: `${new Date().getTime()}-${makeId(4)}` + ("Pickup" || "Dropoff"),
  title: "Pickup Location" || "Drop0off Location",
  position: 1,
  value: "text",
  address: { address: "", lngLat: [] },
  from_time: moment("08:00", "HH:mm"),
  to_time: moment("17:00", "HH:mm"),
  duration: 10,
  phone_numbers: [{ type: "contact", phone: "" }]
};

const pickupsInit = [
  {
    ...init,
    id: `${new Date().getTime()}-${makeId(4)}-Pickup`,
    title: "Pickup Location"
  }
];

const dropoffsInit = [
  {
    ...init,
    id: `${new Date().getTime()}-${makeId(4)}-Dropoff`,
    title: "Drop-off Location"
  }
];

const imagesInit = {
  pickups: {},
  dropoffs: {}
};

const breadCrumbs = [{ text: "ACTIVE ORDERS", url: "/routes" }, { text: "CREATE ORDER" }];

const ClientCreateOrderPage = ({ isAdmin = false }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const newOrder = useSelector(selectNewOrder);
  const isLoading = useSelector((state) => state.global.isLoading);

  const profileCtx = useContext(ProfileContext);
  const history = useHistory();
  const profile = useMemo(() => profileCtx?.profile, [profileCtx]);
  const [clientId, setClientId] = useState();
  const [clientType, setClientType] = useState();
  const [driverId, setDriverId] = useState();
  const [jobs, setJobs] = useState({});
  const [routes, setRoutes] = useState(routesInit);
  const [pickupDate, setPickupDate] = useState(moment(new Date(), DAY_DATE_FORMAT));
  const [form] = Form.useForm();
  const [formPack] = Form.useForm();
  const [formSelectPack] = Form.useForm();
  const [packages, setPackages] = useState([]);
  const [pickups, setPickups] = useState(pickupsInit);
  const [dropoffs, setDropoffs] = useState(dropoffsInit);
  const [visible, setVisible] = useState(false);
  const [isAllPackage, setIsAllPackage] = useState(true);
  const [isOptimizeRoute, setIsOptimizeRoute] = useState(true);
  const [type, setType] = useState("pickups");
  const [selectedPack, setSelectedPack] = useState(null);
  const [keysPick, setKeysPick] = useState([pickupsInit[0].id]);
  const [keysDrop, setKeysDrop] = useState([dropoffsInit[0].id]);
  const [showImport, setShowImport] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({
    id: null,
    typePack: null
  });
  const [imagesAddressInfo, setImagesAddressInfo] = useState(imagesInit);
  const [idErrors, setIdErrors] = useState({
    pickups: [],
    dropoffs: []
  });
  const [isConfirmRoute, setIsConfirmRoute] = useState(false);

  const packageTypeList = useSelector(selectAllPackageTypes);
  const flexioServices = useSelector(selectAllFlexioServices)?.filter(
    (service) => !isAdmin || !service.isInternalDriver
  );

  const [isEmptyPickupAddress, setIsEmptyPickupAddress] = useState(
    !pickups.map((pickup) => pickup.address.lngLat[0] !== undefined).every((item) => item)
  );
  const [isEmptyDropoffAddress, setIsEmptyDropoffAddress] = useState(
    !dropoffs
      .map((dropoff) => dropoff.address.lngLat[0] !== undefined)
      .every((item) => item)
  );

  const [isEmptyPhone, setIsEmptyPhone] = useState();

  const queryParams = new URLSearchParams(window.location.search);

  const isSubscriptionActive = useMemo(
    () => profileCtx?.isSubscriptionActive,
    [profileCtx]
  );
  useEffect(() => {
    if (isSubscriptionActive === false) {
      return history.push("/routes");
    }
  }, [isSubscriptionActive]);

  const expandIconDynamic = (props) => {
    if (props.isActive) {
      return <ReactSVG src="/icons/collapse.svg" />;
    } else {
      return <ReactSVG src="/icons/dropoff.svg" />;
    }
  };
  const disAllPack = dropoffs.length > 1;

  useEffect(() => {
    if (queryParams.get("action") === "new") {
      dispatch(resetNewOrderStatuses());
    }
    // eslint-disable-next-line
  }, []);

  const validateProfile = () => {
    const { firstName, lastName, email, phone } = profile;
    const {
      clientName,
      supportEmail,
      supportPhone,
      accountingEmail,
      market,
      industry,
      addressField1,
      country,
      lat,
      lng
    } = profile.client;

    let validateData;
    if (profile?.client.clientType === "organization") {
      validateData = [
        firstName,
        lastName,
        email,
        phone,
        clientName,
        supportEmail,
        supportPhone,
        accountingEmail,
        market,
        industry,
        addressField1,
        country,
        lat,
        lng
      ];
    } else {
      validateData = [
        firstName,
        lastName,
        email,
        phone,
        clientName,
        supportEmail,
        supportPhone,
        addressField1,
        country,
        lat,
        lng
      ];
    }
    const result = validateData.map((property) => property === "" || property === null);
    return result.includes(true);
  };

  useEffect(() => {
    dispatch(getPackageTypeList());
    dispatch(getFlexioServiceList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const defaultPickups = newOrder.pickups || pickupsInit;
    const defaultDropoffs = newOrder.dropoffs || dropoffsInit;
    const defaultJobs = newOrder.jobs || {};
    const defaultRoutes = filterValidRoutes(newOrder.routes) || {};

    setClientId(newOrder?.clientId);
    setDriverId(newOrder?.driverId);
    setIsConfirmRoute(newOrder?.isConfirmRoute || false);
    if (newOrder?.isConfirmRoute) {
      setType("service");
    }
    setJobs(defaultJobs);
    setRoutes(defaultRoutes);
    if (!isEmpty(newOrder)) {
      setIsAllPackage(newOrder.isAllPackage);
      setIsOptimizeRoute(newOrder.isOptimizeRoute);
      if (defaultPickups[0]) setKeysPick([defaultPickups[0].id]);
      if (defaultDropoffs[0]) setKeysDrop([defaultDropoffs[0].id]);
    }
    setPickupDate(
      newOrder.pickupDate
        ? moment(newOrder.pickupDate)
        : moment(new Date(), DAY_DATE_FORMAT)
    );
    setImagesAddressInfo(newOrder?.imagesAddressInfo || imagesInit);
    setPackages(newOrder.packages || []);
    setPickups(defaultPickups);
    setDropoffs(defaultDropoffs);

    form.setFieldsValue({
      pickups: defaultPickups.map((pick) => {
        return {
          ...pick,
          from_time: pick.from_time ? moment(pick.from_time) : null,
          to_time: pick.to_time ? moment(pick.to_time) : null
        };
      }),
      dropoffs: defaultDropoffs.map((drop) => {
        return {
          ...drop,
          from_time: drop.from_time ? moment(drop.from_time) : null,
          to_time: drop.to_time ? moment(drop.to_time) : null
        };
      }),
      clientId: newOrder.clientId,
      driverId: newOrder.driverId
    });
  }, [form, newOrder]);

  const redirectUri = useMemo(
    () => "/orders/confirm-route",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeTypeMenu = async (value) => {
    setType(value.key);
    if (value.key !== "service" && !!idErrors[value.key].length) {
      setTimeout(() => {
        form
          .validateFields()
          .then(() => {})
          .catch(() => {});
      }, 1500);
    }
  };

  const checkDataAdressForm = (data) => {
    const ids = [];
    const fieldRequired = [
      "address",
      // "contact_name",
      "from_time",
      "to_time",
      "duration"
      // "phoneList"
    ];
    data.forEach((element) => {
      let isError = false;
      fieldRequired.forEach((field) => {
        let value = element[field];
        if (field === "address") value = element.address?.address;
        // if (field === "phoneList") {
        //   element.phone_numbers?.forEach((phone) => {
        //     if (!phone.phone) isError = true;
        //   });
        //   return;
        // }
        if (!isError && !value) isError = true;
      });
      if (isError) ids.push(element.id);
    });
    return ids;
  };

  const onFinishOrder = async () => {
    let messageError = [];
    setKeysDrop([]);
    setKeysPick([]);
    const newIdsError = { ...idErrors };

    if (validateProfile() === true) {
      messageError.push(
        "Error: You cannot create a job until you fill out your profile information. Please update your profile before proceeding."
      );
    }

    const messPack = checkPackages();
    if (messPack && messPack.length) messageError = messageError.concat(messPack);

    const routeValidationMessages = checkRoutes();
    if (routeValidationMessages?.length)
      messageError = messageError.concat(routeValidationMessages);

    const idsErrorsPick = await checkDataAdressForm(pickups);
    if (idsErrorsPick.length) {
      messageError.push("Pickup is missing");
    }

    const idsErrorsDrop = await checkDataAdressForm(dropoffs);
    if (idsErrorsDrop.length) {
      messageError.push("Drop-off is missing");
    }

    newIdsError.pickups = idsErrorsPick;
    newIdsError.dropoffs = idsErrorsDrop;
    setIdErrors(newIdsError);

    if (messageError.length) {
      const descriptionElement = (
        <Paragraph style={{ marginRight: 15 }}>
          <ul>
            {messageError.map((message, i) => (
              <li key={`mess-${i}`}>{message}</li>
            ))}
          </ul>
        </Paragraph>
      );
      notification.error({
        description: descriptionElement,
        placement: "topRight",
        style: {
          width: "auto"
        }
      });
      form.validateFields();
    } else {
      saveData();
    }
  };

  const checkRoutes = () => {
    const routeErrors = [];
    const routeKeys = Object.keys(routes);
    if (routeKeys?.length) {
      for (const routeKey of routeKeys) {
        const route = routes[routeKey];
        if (!route?.vehicleCategoryId && !isInternalDriverService(route?.service?.name)) {
          routeErrors.push(`${route.name}: Please choose a Vehicle for the route `);
        }
        if (!route?.serviceId) {
          routeErrors.push(`${route.name}: Please choose a Service for the route`);
        }
      }
    }
    return routeErrors;
  };

  const checkPackages = () => {
    const pickupsIdsHasPack = uniq(packages.map((pack) => pack.pickupId));
    const picksNotPack = pickups.filter((p) => !pickupsIdsHasPack.includes(p.id)) || [];
    const messageError = [];
    if (picksNotPack.length) {
      `${picksNotPack.map((pick) => {
        if (pick) {
          messageError.push(
            `${pick.title} ${pick.position}: Please select some packages`
          );
        }
      })}`;
    }

    let isQuantityCurrent = false;
    if (!isAllPackage) {
      console.log("isAllPackage", isAllPackage);

      packages.forEach((pack) => {
        const quantityCurrent = pack.quantity;
        if (pack?.dropoffs?.length) {
          const quantityTotal = sumBy(pack.dropoffs, "quantity");
          isQuantityCurrent = quantityCurrent - quantityTotal !== 0;
        } else {
          isQuantityCurrent = true;
        }
      });
    }
    if (isQuantityCurrent) {
      messageError.push("Some packages haven't been assigned to any drop-off");
    }

    return messageError;
  };

  const handleAddJobsToRoutes = () => {
    const tmpJobs = convertObjectToArray(jobs, "id");
    const unscheduledJobs = tmpJobs.filter((j) => j.routeId === "unscheduled");
    let tmpRoutes = {};
    Object.keys(newOrder.routes).map((key) => {
      if (routes[key].polylines) {
        const findJobs = tmpJobs.filter((j) => j.routeId === key);
        if (findJobs?.length > 0) {
          tmpRoutes = {
            ...tmpRoutes,
            [key]: {
              ...newOrder.routes[key],
              jobs: findJobs
            }
          };
        }
      }
      tmpRoutes = {
        ...tmpRoutes,
        [key]: {
          ...tmpRoutes[key],
          serviceId: routes[key]?.serviceId,
          vehicleCategoryId: routes[key]?.vehicleCategoryId,
          vehicle: routes[key]?.vehicleCategory,
          clientEstimatePrice: routes[key]?.clientEstimatePrice,
          driverEstimatePrice: routes[key]?.driverEstimatePrice,
          clientRouteEstimate: {
            ...(routes[key]?.clientRouteEstimate || routeEstimateDefault),
            type: ROUTE_ESTIMATE_TYPES.CLIENT_ESTIMATE
          },
          driverRouteEstimate: {
            ...(routes[key]?.driverRouteEstimate || routeEstimateDefault),
            type: ROUTE_ESTIMATE_TYPES.DRIVER_ESTIMATE
          }
        }
      };
    });

    if (unscheduledJobs?.length > 0) {
      tmpRoutes = {
        ...tmpRoutes,
        unscheduled: {
          name: "unscheduled",
          color: "#c4c4c4",
          jobs: unscheduledJobs
        }
      };
    }
    return convertObjectToArray(tmpRoutes, "id");
  };

  const saveData = async () => {
    const fields = {
      isOptimize: isOptimizeRoute,
      isSamePackages: isAllPackage,
      jobs: convertObjectToArray(newOrder?.jobs, "id"),
      routes: handleAddJobsToRoutes(),
      pickupDate: formatDateToBackend(pickupDate),
      userId: currentUser?.sub,
      clientId,
      driverId
    };

    const { error } = await dispatch(await doCreateActiveOrder(fields));
    if (!error) {
      form.resetFields();
      setImagesAddressInfo(imagesInit);
      dispatch(saveAsDraftNewOrder({}));
      history.push("/routes");
    }
  };

  const handleSelectPack = (values) => {
    const newPackages = [...packages];
    const packsData = values.packs;
    const packIds = values.packs.map((p) => p.id);
    packages.forEach((packa) => {
      if (!packIds.includes(packa.id)) return;
      const indexPack = newPackages.findIndex((newP) => packa.id === newP.id);
      const packSend = packsData.find((packD) => packa.id === packD.id);
      const packDropoffs = newPackages[indexPack]?.dropoffs
        ? [...newPackages[indexPack]?.dropoffs]
        : [];
      const indexDrop = packDropoffs.findIndex((drop) => values.dropId === drop.id);
      if (packSend.value === 0 && indexDrop === -1) return;
      if (packSend.value === 0 && indexDrop !== -1) {
        packDropoffs.splice(indexDrop, 1);
      } else if (packDropoffs.length === 0) {
        packDropoffs.push({
          id: values.dropId,
          quantity: packSend.value
        });
      } else if (indexDrop === -1) {
        packDropoffs.push({
          id: values.dropId,
          quantity: packSend.value
        });
      } else {
        packDropoffs.splice(indexDrop, 1, {
          id: values.dropId,
          quantity: packSend.value
        });
      }
      newPackages[indexPack] = {
        ...newPackages[indexPack],
        dropoffs: packDropoffs
      };
      setPackages(newPackages);
    });
    onCancel();
  };

  const clickModal = () => {
    return selectedInfo.typePack !== "dropoff" ? formPack : formSelectPack;
  };

  const getPackForDrop = (dropId) => {
    if (isAllPackage) {
      return packages;
    } else {
      return packages.filter((pack) => {
        return (
          pack && pack.dropoffs?.length && pack.dropoffs.map((d) => d.id).includes(dropId)
        );
      });
    }
  };

  const getPacksFromSelect = (dropId) => {
    return packages.filter((pack) => {
      if (pack.dropoffs && pack.dropoffs.length) {
        const quantityCurrent = sumBy(
          pack.dropoffs.filter((drop) => drop.id !== dropId),
          "quantity"
        );
        return !(pack.quantity - quantityCurrent === 0);
      }
      return true;
    });
  };

  const onValuesChange = (changedFields, allFields) => {
    if (changedFields.pickups) {
      setPickups(allFields.pickups);
      setIsConfirmRoute(false);
    }
    if (changedFields.dropoffs) {
      setDropoffs(allFields.dropoffs);
      setIsConfirmRoute(false);
    }
  };

  const onchangeIsAllPackage = (checked, e) => {
    e.preventDefault();
    setIsAllPackage(checked);
    setIsConfirmRoute(false);
  };

  const handleSaveAsDraft = (callback = () => {}) => {
    if (isConfirmRoute) {
      dispatch(
        saveAsDraftNewOrder({
          data: {
            ...newOrder
          },
          callback
        })
      );
    } else {
      const dataForm = form.getFieldValue();
      // let defaultRoutes = newOrder?.routes || routesInit;
      const tmpJobs = getJobs({
        imagesAddressInfo,
        packages,
        pickups,
        dropoffs,
        isAllPackage,
        isOptimizeRoute
      });
      let firstPickup = {};
      if (dataForm.pickups[0]) {
        firstPickup = {
          ...dataForm.pickups[0]
        };
      }
      const objJobs = convertArrayToObject(tmpJobs, "id");
      setJobs(objJobs);
      dispatch(
        saveAsDraftNewOrder({
          data: {
            ...newOrder,
            pickups: dataForm.pickups,
            dropoffs: dataForm.dropoffs,
            isOptimizeRoute,
            imagesAddressInfo,
            isAllPackage,
            packages,
            pickupDate,
            jobs: objJobs,
            isConfirmRoute,
            firstPickup,
            clientId,
            driverId
          },
          callback
        })
      );
    }

    dispatch(setSuccessMessage("Saved"));
  };

  const handleSaveAsDraftByManual = () => {
    const dataForm = form.getFieldValue();
    let tmpJobs = newOrder.jobs;
    let firstPickup = newOrder.firstPickup;
    if (!isConfirmRoute) {
      tmpJobs = getJobs({
        imagesAddressInfo,
        packages,
        pickups,
        dropoffs,
        isAllPackage,
        isOptimizeRoute
      });
      if (dataForm.pickups[0]) {
        firstPickup = {
          ...dataForm.pickups[0]
        };
      }
      tmpJobs = convertArrayToObject(tmpJobs, "id");
    }
    setJobs(tmpJobs);
    dispatch(
      saveAsDraftNewOrder({
        data: {
          ...newOrder,
          routes: { ...newOrder.routes },
          pickups: dataForm.pickups,
          dropoffs: dataForm.dropoffs,
          isOptimizeRoute,
          imagesAddressInfo,
          isAllPackage,
          packages,
          pickupDate,
          jobs: tmpJobs,
          isConfirmRoute,
          firstPickup,
          clientId,
          driverId
        }
      })
    );
    dispatch(setSuccessMessage("Saved"));
  };

  const handleResetAll = () => {
    form.resetFields();
    setImagesAddressInfo(imagesInit);
    dispatch(saveAsDraftNewOrder({ data: {} }));
    dispatch(setSuccessMessage("Reset"));
  };

  const changeCollapsePick = (props) => {
    setKeysPick(props);
    const idsErrorPick = keysPick.filter((x) => idErrors.pickups.includes(x));
    if (idsErrorPick.length)
      setTimeout(() => {
        form
          .validateFields()
          .then(() => {})
          .catch(() => {});
      }, 1500);
  };

  const changeCollapsDrop = (props) => {
    setKeysDrop(props);
    const idsErrorDrop = keysDrop.filter((x) => idErrors.dropoffs.includes(x));
    if (idsErrorDrop.length)
      setTimeout(() => {
        form
          .validateFields()
          .then(() => {})
          .catch(() => {});
      }, 1500);
  };
  const addPickup = () => {
    const pickupsForm = form.getFieldValue("pickups");
    const maxPosition = Math.max(...pickupsForm.map((p) => p.position), 1);
    return {
      ...init,
      id: `${new Date().getTime()}-${makeId(4)}-Pickup`,
      title: "Pickup Location",
      position: maxPosition + 1
    };
  };

  const addDropoff = () => {
    const dropoffsForm = form.getFieldValue("dropoffs");
    const maxPosition = Math.max(...dropoffsForm.map((p) => p.position), 1);
    return {
      ...init,
      id: `${new Date().getTime()}-${makeId(4)}-Dropoff`,
      title: "Drop-off Location",
      position: maxPosition + 1
    };
  };

  const handleHeader = (pickup, index, name, remove, array, stepType) => {
    const packagesByPick = packages.filter((pa) => pa.pickupId === pickup.id);
    return (
      <div className="header-collapse">
        <div className="top">
          <h3>
            {pickup?.location_name || handleTitleTab(pickup.title, pickup.position) || ""}
          </h3>
          {!array.includes(pickup.id) && pickup.contact_name && (
            <>
              <h3>&nbsp; - {pickup.contact_name}</h3>
              <div> &nbsp; - {pickup?.address?.address}</div>
            </>
          )}
        </div>
        {packagesByPick.length > 0 && !array.includes(pickup.id) && (
          <div className="bottom">
            {packagesByPick.length} packages • Total Quantity:{" "}
            {sumBy(packagesByPick, (pack) => Number(pack.quantity))}
          </div>
        )}
        {index > 0 && (
          <Popconfirm
            title="Are you sure you want to delete this location?"
            onConfirm={(e) => {
              handleClickDeletePickup(name, remove, e, pickup.id, stepType);
            }}
            okText="Yes"
            cancelText="No"
          >
            <ReactSVG
              className="icon"
              src="/icons/delete-icon.svg"
              style={{
                top:
                  packagesByPick.length > 0 && !array.includes(pickup.id) ? "14px" : "3px"
              }}
            />
          </Popconfirm>
        )}
      </div>
    );
  };

  const onCancel = () => {
    formPack.resetFields();
    setSelectedPack(null);
    setSelectedInfo({});
    setVisible(false);
  };

  const openPackCreateUpdate = (ac, id, typePack, pack = null) => {
    setVisible(true);
    setSelectedPack(pack);
    setSelectedInfo({
      id,
      typePack
    });
    setIsConfirmRoute(false);
  };

  const openSelectedPack = (dropId) => {
    setSelectedInfo({
      id: dropId,
      typePack: "dropoff"
    });
    setVisible(true);
    setIsConfirmRoute(false);
  };

  const handleSavePack = (values) => {
    const pickupId = selectedInfo?.id;
    const newPackages = [...packages];
    if (!values.id) {
      newPackages.push({
        ...values,
        pickupId: pickupId,
        id: `${new Date().getTime()}-${pickupId}pack`
      });
    } else {
      const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
      delete values.id;
      const newPack = {
        ...newPackages[indexpack],
        ...values
      };
      newPackages.splice(indexpack, 1, newPack);
    }
    setPackages(newPackages);
    onCancel();
  };

  const removePack = (values) => {
    const newPackages = [...packages];
    const indexpack = newPackages.findIndex((pack) => pack.id === values.id);
    newPackages.splice(indexpack, 1);
    setPackages(newPackages);
  };

  const onChangePickupLocationSearch = (value, pickupId) => {
    const pickupsForm = [...form.getFieldValue("pickups")];
    const indexPickup = pickupsForm.findIndex((pickup) => pickup.id === pickupId);
    pickupsForm[indexPickup].location_name = value;
    setPickups(pickupsForm);
  };
  const onChangeDropoffLocationSearch = (value, pickupId) => {
    const dropoffsFrom = [...form.getFieldValue("dropoffs")];
    const indexDropoff = dropoffsFrom.findIndex((pickup) => pickup.id === pickupId);
    dropoffsFrom[indexDropoff].location_name = value;
    setDropoffs(dropoffsFrom);
  };

  const onChangePickupAddress = (value, pickupId) => {
    const pickupsFrom = [...form.getFieldValue("pickups")];
    const indexPickup = pickupsFrom.findIndex((pickup) => pickup.id === pickupId);
    const pickupFrom = { ...pickupsFrom[indexPickup] };
    if (pickupFrom) {
      pickupFrom.address = {
        address: value.address,
        lngLat: value.lngLat
      };
      pickupsFrom.splice(indexPickup, 1, pickupFrom);
      form.setFieldsValue({
        pickups: pickupsFrom
      });
      setPickups(pickupsFrom);
    }
    setIsConfirmRoute(false);
  };

  const onChangeDropoffAddress = (value, dropoffId) => {
    const dropoffFrom = [...form.getFieldValue("dropoffs")];
    const indexDropoff = dropoffFrom.findIndex((dropoff) => dropoff.id === dropoffId);
    const pickupFrom = { ...dropoffFrom[indexDropoff] };
    if (pickupFrom) {
      pickupFrom.address = {
        address: value.address,
        lngLat: value.lngLat
      };
      dropoffFrom.splice(indexDropoff, 1, pickupFrom);
      form.setFieldsValue({
        dropoffs: dropoffFrom
      });
      setDropoffs(dropoffFrom);
    }
    setIsConfirmRoute(false);
  };

  useEffect(() => {
    setIsEmptyPickupAddress(
      !pickups
        .map((pickup) => pickup.address.lngLat[0] !== undefined)
        .every((item) => item)
    );
  }, [pickups]);
  useEffect(() => {
    setIsEmptyDropoffAddress(
      !dropoffs
        .map((dropoff) => dropoff.address.lngLat[0] !== undefined)
        .every((item) => item)
    );
  }, [pickups, dropoffs, packages]);

  useEffect(() => {
    const hasEmptyPhone = (items) => {
      return items.some(
        (item) =>
          !item.phone_numbers ||
          item.phone_numbers.length === 0 ||
          item.phone_numbers.some(
            (phoneObj) => !phoneObj.phone || phoneObj.phone.trim() === ""
          )
      );
    };

    const dropoffsHasEmpty = hasEmptyPhone(dropoffs);
    const pickupsHasEmpty = hasEmptyPhone(pickups);

    setIsEmptyPhone(dropoffsHasEmpty || pickupsHasEmpty);
  }, [dropoffs, pickups]);

  const onChangeTime = (value, id, name, fieldName) => {
    const dropoffFrom = [...form.getFieldValue(name)];
    const indexDropoff = dropoffFrom.findIndex((dropoff) => dropoff.id === id);
    const pickupFrom = { ...dropoffFrom[indexDropoff] };
    const tmpObject = {};
    if (pickupFrom) {
      pickupFrom[fieldName] = value;
      dropoffFrom.splice(indexDropoff, 1, pickupFrom);
      tmpObject[name] = dropoffFrom;
      form.setFieldsValue(tmpObject);
      name === "pickups" ? setPickups(dropoffFrom) : setDropoffs(dropoffFrom);
    }
    setIsConfirmRoute(false);
  };

  const handleClickDeletePickup = (name, actionFunc, e, id, stepType) => {
    e.stopPropagation();
    if (stepType === "pickups") {
      const deletePackIds =
        packages.filter((pack) => pack.pickupId === id)?.map((p) => p.id) || [];
      const newPacks = packages.filter((pack) => !deletePackIds.includes(pack.id));
      setPackages(newPacks);
    } else {
      const packsHasDrop =
        packages.filter(
          (pack) => pack.dropoffs?.length > 0 && pack.dropoffs.find((d) => d.id === id)
        ) || [];
      if (packsHasDrop.length) {
        const newPacks = [...packages];
        packsHasDrop.forEach((packD) => {
          packD.dropoffs.splice(
            packD.dropoffs.findIndex(function (i) {
              return i.id === id;
            }),
            1
          );
          const index = packages.findIndex((pa) => pa.id === packD.id);
          newPacks[index] = packD;
        });
        setPackages(newPacks);
      }
    }
    actionFunc(name);
    setIsConfirmRoute(false);
  };

  const handleClickService = () => {
    if (isEmptyPickupAddress === true) {
      notification.error({
        message: "Error!",
        description: "Pickup address is missing.",
        placement: "topRight",
        style: {
          width: "auto"
        }
      });
    }

    if (packages.length === 0) {
      notification.error({
        message: "Error!",
        description: "Add at least 1 packages.",
        placement: "topRight",
        style: {
          width: "auto"
        }
      });
    }

    if (isEmptyDropoffAddress === true) {
      notification.error({
        message: "Error!",
        description: "Dropoff address is missing.",
        placement: "topRight",
        style: {
          width: "auto"
        }
      });
    }

    if (isEmptyPhone === true) {
      notification.error({
        message: "Error!",
        description: "Phone Number is required for all pickups and dropoffs.",
        placement: "topRight",
        style: {
          width: "auto"
        }
      });
    }

    if (
      isEmptyDropoffAddress ||
      isEmptyPickupAddress ||
      isEmptyPhone ||
      packages.length === 0
    )
      return;
    handleSaveAsDraft(history.push(redirectUri));
  };

  return (
    <div className="CreateOrderPage">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 1em"
        }}
      >
        <OrdersHeader breadCrumbs={breadCrumbs} hasButton={false} />
        <Button
          size="small"
          className={`svg-icon  d-flex flex-row`}
          onClick={() => {
            history.push("/import-route");
          }}
        >
          Import <ImportIcon />
        </Button>
      </div>
      <div className="wrapper">
        {!newOrder?.isConfirmRoute && dropoffs.length > 1 && (
          <MapImportPreview routes={[...pickups, ...dropoffs]} />
        )}
        <h2 className="title p-1">Create Order</h2>
        <div className="sider">
          <SiderForm onChangeMenu={onChangeTypeMenu} type={type} />
        </div>
        <div className="contentForm">
          <Form
            onValuesChange={onValuesChange}
            scrollToFirstError={true}
            layout="vertical"
            form={form}
            hideRequiredMark
            colon={false}
            initialValues={{
              pickups: pickupsInit,
              dropoffs: dropoffsInit
            }}
          >
            {type === "dropoffs" && (
              <div className="switchs">
                <div className="switch">
                  <Switch
                    onChange={onchangeIsAllPackage}
                    checked={isAllPackage}
                    disabled={disAllPack}
                  />
                  <p>All packages will be delivered at the same location.</p>
                </div>
              </div>
            )}
            <Form.List name="pickups">
              {(fields, { add, remove }) => {
                return (
                  type === "pickups" && (
                    <>
                      <div className="pickups block">
                        <Collapse
                          activeKey={keysPick}
                          onChange={changeCollapsePick}
                          ghost
                          expandIconPosition="left"
                          expandIcon={(props) => expandIconDynamic(props)}
                        >
                          {fields.map(({ key, name, fieldKey, isListField }, index) => {
                            const pickupData = form.getFieldValue("pickups")[index];
                            return (
                              <Panel
                                header={handleHeader(
                                  pickupData,
                                  index,
                                  name,
                                  remove,
                                  keysPick,
                                  "pickups"
                                )}
                                className={
                                  idErrors["pickups"].includes(pickupData.id) &&
                                  "error-panel"
                                }
                                key={pickupData.id}
                                // extra={genExtra(index, name, remove)}
                              >
                                <AddressForm
                                  stepType="pickups"
                                  setAddress={onChangePickupAddress}
                                  handleLocationNameUpdate={onChangePickupLocationSearch}
                                  address={pickupData.address}
                                  orderData={pickupData}
                                  id={pickupData.id}
                                  keyValue={key}
                                  nameValue={name}
                                  fieldKey={fieldKey}
                                  isListField={isListField}
                                  key={key}
                                  form={form}
                                  onChangeTime={onChangeTime}
                                  imagesAddressInfo={imagesAddressInfo}
                                  setImagesAddressInfo={setImagesAddressInfo}
                                />

                                <PackageList
                                  key={`${key}-pickup-pack`}
                                  packages={packages.filter(
                                    (pa) => pa.pickupId === pickupData.id
                                  )}
                                  pickupId={pickupData.id}
                                  editPack={(pack) =>
                                    openPackCreateUpdate(
                                      "edit",
                                      pickupData.id,
                                      "pickup",
                                      pack
                                    )
                                  }
                                  addPack={() => {
                                    openPackCreateUpdate("new", pickupData.id, "pickup");
                                  }}
                                  removePack={removePack}
                                />
                              </Panel>
                            );
                          })}
                        </Collapse>
                        <Button
                          size="large"
                          className="add-all"
                          onClick={() => add(addPickup())}
                        >
                          Add Pickup Location
                          <ReactSVG src="/icons/location.svg" className="react-icon" />
                        </Button>
                      </div>
                    </>
                  )
                );
              }}
            </Form.List>
            <Form.List name="dropoffs">
              {(fieldDropoffs, { add, remove }) => {
                return (
                  type === "dropoffs" && (
                    <div className="dropoffs block">
                      <Collapse
                        activeKey={keysDrop}
                        onChange={changeCollapsDrop}
                        ghost
                        expandIconPosition="left"
                        expandIcon={(props) => expandIconDynamic(props)}
                      >
                        {fieldDropoffs.map(
                          ({ key, name, fieldKey, isListField }, index) => {
                            const dropoffData = form.getFieldValue("dropoffs")[index];
                            return (
                              <Panel
                                header={handleHeader(
                                  dropoffData,
                                  index,
                                  name,
                                  remove,
                                  keysDrop,
                                  "dropoffs"
                                )}
                                key={dropoffData.id}
                                className={
                                  idErrors["dropoffs"].includes(dropoffData.id) &&
                                  "error-panel"
                                }
                              >
                                <AddressForm
                                  isAdmin={isAdmin}
                                  clientId={clientId}
                                  stepType="dropoffs"
                                  setAddress={onChangeDropoffAddress}
                                  handleLocationNameUpdate={onChangeDropoffLocationSearch}
                                  address={dropoffData.address}
                                  orderData={dropoffData}
                                  id={dropoffData.id}
                                  keyValue={key}
                                  nameValue={name}
                                  fieldKey={fieldKey}
                                  isListField={isListField}
                                  key={key}
                                  form={form}
                                  onChangeTime={onChangeTime}
                                  imagesAddressInfo={imagesAddressInfo}
                                  setImagesAddressInfo={setImagesAddressInfo}
                                />
                                <PackageListDrop
                                  id={dropoffData.id}
                                  packages={getPackForDrop(dropoffData.id)}
                                  selectedPack={() => openSelectedPack(dropoffData.id)}
                                  isAll={isAllPackage}
                                />
                              </Panel>
                            );
                          }
                        )}
                      </Collapse>
                      {!isAllPackage && (
                        <Button
                          size="large"
                          className="add-all"
                          onClick={() => add(addDropoff())}
                        >
                          Add Dropoff Location
                          <ReactSVG src="/icons/location.svg" className="react-icon" />
                        </Button>
                      )}
                    </div>
                  )
                );
              }}
            </Form.List>
            {type === "service" && (
              <ServiceStep
                flexioServices={flexioServices}
                pickupDate={pickupDate}
                setPickupDate={setPickupDate}
                routes={routes}
                setRoutes={setRoutes}
                estimatePriceOrganization={clientType === "organization"}
              />
            )}
          </Form>
        </div>
      </div>
      <div className="footer">
        <div>
          <Button size="large" onClick={handleResetAll}>
            Reset
          </Button>
        </div>
        <div>
          {isConfirmRoute && (
            <Button
              size="large"
              className="svg-icon modify"
              type="link"
              onClick={() => history.push(redirectUri)}
            >
              Modify Route <ReactSVG src="/icons/edit_road.svg" className="icon" />
            </Button>
          )}

          <Button
            size="large"
            className="save-as"
            onClick={() => handleSaveAsDraftByManual()}
          >
            Save as Draft <ReactSVG src="/icons/draf.svg" className="icon" />
          </Button>
          <Button
            type="primary"
            size="large"
            className="create-order"
            onClick={isConfirmRoute ? onFinishOrder : handleClickService}
            loading={isLoading}
          >
            {isConfirmRoute ? "Create Jobs" : "Confirm Route"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </div>
      <Modal
        title={
          !selectedPack && selectedInfo.typePack !== "dropoff" ? (
            <div>Add Package</div>
          ) : selectedPack && selectedInfo.typePack !== "dropoff" ? (
            <div>Edit Package</div>
          ) : (
            <div>Select Package</div>
          )
        }
        visible={visible}
        onCancel={onCancel}
        footer={
          <div className="buttons-modal">
            <Form.Item>
              <Button size="large" onClick={onCancel}>
                Cancel
              </Button>
              <Button size="large" type="primary" onClick={() => clickModal().submit()}>
                Confirm
                <CheckCircleOutlined />
              </Button>
            </Form.Item>
          </div>
        }
        width={792}
      >
        {selectedInfo.typePack !== "dropoff" && (
          <PackForm
            pack={selectedPack}
            formPack={formPack}
            onOk={handleSavePack}
            packageTypeList={packageTypeList}
          />
        )}
        {selectedInfo.typePack === "dropoff" && (
          <SelectPack
            packages={getPacksFromSelect(selectedInfo.id)}
            packageTypeList={packageTypeList}
            formSelectPack={formSelectPack}
            dropId={selectedInfo.id}
            onOk={handleSelectPack}
          />
        )}
      </Modal>
    </div>
  );
};
export default withPermission(ClientCreateOrderPage, [UserRole.ADMIN, UserRole.MANAGER]);
