import { isPointInPolygon } from "geolib";
import { makeId } from "helper/util";
import { uniq, flatten as _flatten, isEmpty, uniqWith, isEqual } from "lodash";
import moment from "moment";

export const initRoutes = () => {
  let routesOptimize = {};

  [...Array(10)].map((_, index) => {
    routesOptimize[`route_${index}`] = {
      display: true,
      address: "",
      name: `Route ${index + 1}`
      // capacity: {
      //   length: 0,
      //   weight: 0,
      //   volume: 0
      // }
    };
  });
  return routesOptimize;
};

export const handleRoutesOptimize = (order, numberRoutes = 20, routes = []) => {
  const nullRoutes = handleNullRoutes(order, numberRoutes, routes);
  return { ...nullRoutes };
};

export const cloneStartLocationToEndLocation = (fleet) => {
  const fleetTemp = Object.assign({}, fleet);
  Object.keys(fleetTemp).forEach((key) => {
    if (fleetTemp[key]?.start_location) {
      fleetTemp[key].end_location = fleetTemp[key].start_location;
    }
    if (fleetTemp[key]?.shift_start) delete fleetTemp[key]?.shift_start;
  });
  return fleetTemp;
};

export const deleteShiftStart = (fleet) => {
  const fleetTemp = Object.assign({}, fleet);
  Object.keys(fleetTemp).forEach((key) => {
    if (fleetTemp[key]?.shift_start) delete fleetTemp[key]?.shift_start;
  });
  return fleetTemp;
};

export const handleNullRoutes = (order, numberRoute = 20, originRoutes = []) => {
  let routes = {};
  let firstPickup = {};

  if (order?.pickups?.length) {
    firstPickup = {
      ...order.pickups[0]
    };
  }
  // const maxCapacity = order?.dataService?.service?.maxCapacityVehicle;
  for (let i = 1; i <= numberRoute; i++) {
    const name = `route_${i}`;
    const assignedName = `assigned_route_${i}`;
    let route = { locked: false };
    if (originRoutes.length > 0) {
      route = originRoutes.find((r) => r.id === name);
    }

    routes[name] = {
      type: [name, assignedName],
      // capacity: {
      //   weight: maxCapacity?.maxWeight,
      //   length: maxCapacity?.maxLength,
      //   volume: maxCapacity?.maxVolume
      // },
      start_location: {
        name: firstPickup?.address?.address,
        lat: firstPickup?.address?.lngLat[1],
        lng: firstPickup?.address?.lngLat[0]
      },
      shift_start: firstPickup.start || "08:00",
      shift_end: getShiftEnd(firstPickup.start),
      locked: !!route?.locked
    };
  }
  return routes;
};

export const handleExistingRoutesOptimize = (order, { id, locked }) => {
  const assignedName = `assigned_${id}`;
  const routes = {};

  routes[id] = {
    type: [id, assignedName],
    start_location: {
      name: order.startAddress,
      lat: order.startAddressLat,
      lng: order.startAddressLng
    },
    shift_start: order.shiftStart || "08:00",
    shift_end: getShiftEnd(order.shiftStart),
    locked: !!locked
  };
  return { ...routes };
};

const getShiftEnd = (start = "08:00") => {
  const splitStart = start.split(":");
  const hour = parseInt(splitStart[0]);
  return hour + 12 > 24 ? "23:59" : `${hour + 12}:${splitStart[1]}`;
};

const mapDataPackage = (packs) => {
  return packs.map((pack) => {
    return {
      maxLength: pack.maxLength,
      maxWeight: pack.maxWeight,
      maxVolume: pack.maxVolume,
      name: pack.name,
      quantity: pack.quantity,
      packageTypeId: pack?.packageTypeId,
      barcode: pack?.barcode,
      packageV2: pack?.packageV2
    };
  });
};

export const mapDataAddress = (values, type, imagesAddressInfo) => {
  const images =
    (imagesAddressInfo[`${type}s`] && imagesAddressInfo[`${type}s`][values.id]) || [];
  return {
    id: values.id,
    address: values.address.address,
    lat: values.address?.lngLat?.length ? values.address.lngLat[1] : undefined,
    lng: values.address?.lngLat?.length ? values.address.lngLat[0] : undefined,
    locationName: values.location_name,
    physicalAddress: values.physical_address,
    contactName: values.contact_name,
    start: values.from_time
      ? typeof values.from_time === "object"
        ? values.from_time.format("HH:mm")
        : moment(values.from_time).format("HH:mm")
      : undefined,
    end: values.to_time
      ? typeof values.to_time === "object"
        ? values.to_time.format("HH:mm")
        : moment(values.to_time).format("HH:mm")
      : undefined,
    email: values.email,
    duration: values.duration,
    phoneList: values.phone_numbers,
    note: values.note,
    routeId: values?.routeId,
    type,
    files: images?.map((image) => image.id),
    internalRouteId: values.internalRouteId,
    internalOrderId: values.internalOrderId,
    internalCustomerId: values.internalCustomerId,
    salesPerson: values.salesPerson,
    cod: values.cod
  };
};

export const getJobs = (order) => {
  const {
    packages,
    pickups,
    dropoffs,
    isAllPackage,
    isOptimizeRoute,
    imagesAddressInfo
  } = order;
  const jobs = [];
  const packagesByPick = {};
  packages.forEach((pack) => {
    packagesByPick[pack.pickupId] = packagesByPick[pack.pickupId] || [];
    packagesByPick[pack.pickupId].push(pack);
  });

  Object.keys(packagesByPick).map((pickId) => {
    const pick = pickups.find((p) => String(p.id) === String(pickId));

    if (isAllPackage) {
      const rs = {
        id: `job_${makeId(4)}`,
        pickup: {},
        dropoff: {},
        packages: []
      };
      const drop = dropoffs[0];

      rs.pickup = mapDataAddress(pick, "pickup", imagesAddressInfo);
      rs.dropoff = mapDataAddress(drop, "dropoff", imagesAddressInfo);
      rs.packages = mapDataPackage(packagesByPick[pickId]);

      jobs.push(rs);
    } else {
      let dropIds = [];
      packagesByPick[pickId].forEach((pack) => {
        pack.dropoffs?.length > 0 &&
          pack.dropoffs.forEach((drop) => dropIds.push(drop.id));
      });
      dropIds = uniq(dropIds);
      dropIds.forEach((dropId) => {
        const rs = {
          id: `job_${makeId(4)}`,
          pickup: {},
          dropoff: {},
          packages: []
        };
        const drop = dropoffs.find((d) => d.id === dropId);
        const packsByDrop = [];
        packagesByPick[pickId].forEach((pack) => {
          if (
            pack.dropoffs?.length &&
            pack.dropoffs.map((dr) => dr.id).includes(dropId)
          ) {
            const packDrop = pack.dropoffs.find((dro) => dro.id === dropId);
            packsByDrop.push({
              ...pack,
              quantity: packDrop.quantity
            });
          }
        });

        rs.pickup = mapDataAddress(pick, "pickup", imagesAddressInfo);
        rs.dropoff = mapDataAddress(drop, "dropoff", imagesAddressInfo);
        rs.packages = mapDataPackage(packsByDrop);
        rs.isOptimize = isOptimizeRoute;
        rs.isSamePackages = isAllPackage;
        jobs.push(rs);
      });
    }
  });
  return jobs;
};

export const dataJobTypeOptimize = (jobs) => {
  if (jobs.length === 0) {
    return {};
  }
  const data = {};
  jobs.map((job, index) => {
    data[job.id] = {
      pickup: {
        location: {
          name: job.pickup.address,
          lat: job.pickup.lat,
          lng: job.pickup.lng
        },
        start: job.pickup.start,
        end: job.pickup.end,
        duration: Number(job.pickup.duration)
      },
      dropoff: {
        location: {
          name: job.dropoff.address,
          lat: job.dropoff.lat + index * 0.00000000000001,

          lng: job.dropoff.lng
        },
        start: job.dropoff.start,
        end: job.dropoff.end,
        duration: Number(job.dropoff.duration)
      }
      // load: calculateLoad(job.packages)
    };
  });
  return data;
};

export const calculateLoad = (packages) => {
  let load = {
    // length: 0,
    // weight: 0,
    volume: 0
  };
  packages.map((pack) => {
    load = {
      // length: load.length + pack.maxLength * pack.quantity,
      // weight: load.volume + pack.maxWeight * pack.quantity,
      volume: load.volume + pack.maxVolume * pack.quantity
    };
  });
  return load;
};

export const dataJobsToLocations = (jobs) => {
  const locations = [];
  jobs.map((job) => {
    locations.push({
      jobId: job.id,
      color: job?.color,
      id: `${job.id}_pickup`,
      address: job.pickup.address,
      lat: job.pickup.lat,
      lng: job.pickup.lng,
      type: "pickup",
      internalDriverId: job.internalDriverId,
      start: job.pickup.start,
      end: job.pickup.end,
      duration: job.pickup.duration
    });
    locations.push({
      jobId: job.id,
      color: job?.color,
      id: `${job.id}_dropoff`,
      address: job.dropoff.address,
      lat: job.dropoff.lat,
      lng: job.dropoff.lng,
      type: "dropoff",
      internalDriverId: job.internalDriverId,
      start: job.dropoff.start,
      end: job.dropoff.end,
      duration: job.dropoff.duration
    });
  });
  return locations;
};

export function addPropertiesToPolygon(poligon) {
  if (!poligon?.length) return [];
  const flattenPoligon = _flatten(poligon);
  return flattenPoligon.map((item) => ({ longitude: item[0], latitude: item[1] }));
}

export function verifyPickupLocations(selectedService, pickups) {
  if (!selectedService?.pickupCoords?.length) {
    return pickups;
  }

  const outsidePickups = [];
  const pickupGeofence = addPropertiesToPolygon(selectedService?.pickupCoords);
  for (const pickup of pickups) {
    if (
      pickup?.lat &&
      pickup?.lng &&
      !isPointInPolygon({ lat: pickup.lat, lng: pickup.lng }, pickupGeofence)
    ) {
      outsidePickups.push(pickup);
    }
  }
  return outsidePickups;
}

export function verifyDropLocations(selectedService, dropoffs) {
  if (!selectedService?.pickupCoords?.length) {
    return dropoffs;
  }

  const outsideDropoffs = [];
  const deliveryGeofence = addPropertiesToPolygon(selectedService?.deliveryCoords);
  for (const dropoff of dropoffs) {
    if (
      dropoff?.lat &&
      dropoff?.lng &&
      !isPointInPolygon({ lat: dropoff.lat, lng: dropoff.lng }, deliveryGeofence)
    ) {
      outsideDropoffs.push(dropoff);
    }
  }
  return outsideDropoffs;
}

export function verifyPackages(selectedService, packages) {
  if (!selectedService?.packageTypes?.length) {
    return packages;
  }

  const notAcceptedPackageTypes = [];
  const acceptedPackageTypeIds = selectedService?.packageTypes?.map(
    (packageType) => packageType.id
  );
  for (const pack of packages) {
    if (!acceptedPackageTypeIds.includes(pack.packageTypeId)) {
      notAcceptedPackageTypes.push(pack);
    }
  }
  return notAcceptedPackageTypes;
}

export function buildPricingEstimateBody(route) {
  const packagesPerRoute = getTotalPackagesPerRoute(route);

  const pickupsQuantity = [];
  const deliveriesQuantity = [];
  route.jobs.forEach((job) => {
    if (job?.pickup?.address) {
      pickupsQuantity.push({
        address: job.pickup.address,
        start: job.pickup.start
      });
    }

    if (job?.dropoff?.address) {
      deliveriesQuantity.push({
        address: job.dropoff.address,
        start: job.dropoff.start
      });
    }
  });

  return {
    serviceId: route.serviceId,
    totalRouteTime: (route.workingMins / 60).toFixed(2),
    totalRouteDistance: parseFloat((route.distance / 1609.344).toFixed(2)),
    totalReturnDistance: parseFloat(((route.returnDistance || 0) / 1609.344).toFixed(2)),
    totalReturnTime: (route.returnMins / 60).toFixed(2),
    totalMicroPackages: packagesPerRoute.totalMicroPackages,
    totalSmallPackages: packagesPerRoute.totalSmallPackages,
    totalMediumPackages: packagesPerRoute.totalMediumPackages,
    totalLargePackages: packagesPerRoute.totalLargePackages,
    totalCustomPackages: packagesPerRoute.totalCustomPackages,
    totalPalletPackages: packagesPerRoute.totalPalletPackages,
    totalPickupStops: pickupsQuantity.length,
    totalDeliveryStops: deliveriesQuantity.length,
    totalReturnDeliveryStops: 1, // <--- @Vuong How do we get this?
    vehicleCategoryId: route.vehicleCategory?.id
  };
}

export function getTotalPackagesPerRoute(route) {
  let totalMicroPackages = 0;
  let totalSmallPackages = 0;
  let totalMediumPackages = 0;
  let totalLargePackages = 0;
  let totalCustomPackages = 0;
  let totalPalletPackages = 0;

  for (let i = 0; i < route.jobs.length; i++) {
    const job = route.jobs[i];

    const microPackage = job.packages.find((item) => {
      return item.name === "Micro";
    });
    const smallPackage = job.packages.find((item) => {
      return item.name === "Small";
    });
    const mediumPackage = job.packages.find((item) => {
      return item.name === "Medium";
    });
    const largePackage = job.packages.find((item) => {
      return item.name === "Large";
    });
    const customPackage = job.packages.find((item) => {
      return item.name === "Custom";
    });
    const palletPackage = job.packages.find((item) => {
      return item.name === "Pallet";
    });

    totalSmallPackages = totalSmallPackages + smallPackage?.quantity || 0;
    totalMicroPackages = totalMicroPackages + microPackage?.quantity || 0;
    totalMediumPackages = totalMediumPackages + mediumPackage?.quantity || 0;
    totalLargePackages = totalLargePackages + largePackage?.quantity || 0;
    totalCustomPackages = totalCustomPackages + customPackage?.quantity || 0;
    totalPalletPackages = totalPalletPackages + palletPackage?.quantity || 0;
  }

  return {
    totalMicroPackages,
    totalSmallPackages,
    totalMediumPackages,
    totalLargePackages,
    totalCustomPackages,
    totalPalletPackages
  };
}

export const routeEstimateDefault = {
  additionalTimeOnRoute: 0,
  basePrice: 0,
  bookingFee: 0,
  estimatePrice: 0,
  fuelSurcharge: 0,
  overDeliveryStops: 0,
  preSubTotal: 0,
  priceAdditionalInsurance: 0,
  priceAdditionalTimeOnRoute: 0,
  priceB2BTax: 0,
  priceCustomPackage: 0,
  priceDeliveryStops: 0,
  priceDistanceOnRoute: 0,
  priceFuelSurcharge: 0,
  priceLargePackage: 0,
  priceMediumPackage: 0,
  priceMicroPackage: 0,
  priceMinimumDeliveries: 0,
  priceMinimumEarnings: 0,
  priceOverDeliveryStops: 0,
  pricePalletPackage: 0,
  pricePickupStops: 0,
  priceReturnDeliveryStop: 0,
  priceReturnDistanceLong: 0,
  priceReturnDistanceShort: 0,
  priceReturnTime: 0,
  priceSalesTax: 0,
  priceSmallPackage: 0,
  priceTotalPackages: 0,
  returnDeliveryStop: 0,
  subTotal: 0,
  totalRouteTime: 0,
  volumePackageDiscounts: 0
};

export const filterValidRoutes = (routes = {}) => {
  const validRoutes = { ...routes };
  for (const routeKey of Object.keys(routes)) {
    if (!routes[routeKey]?.jobs?.length) {
      delete validRoutes[routeKey];
    }
  }
  return validRoutes;
};

export const getLastAddress = (route = {}) => {
  if (isEmpty(route || isEmpty(route.jobs))) return "Unknown";
  const { jobs } = route;
  return jobs[jobs.length - 1].dropoff?.address;
};
