import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import PhoneInput from "react-phone-input-2";
import _isEmpty from "lodash/isEmpty";
import _flattenDeep from "lodash/flattenDeep";
import _find from "lodash/find";

import AddressInput from "components/inputs/AddressInput";
import DefaultMapbox from "modules/client/active-orders/components/mapbox/DefaultMapbox";
import React, { useRef, useState } from "react";
import "./ReturnProcess.scss";
import { useEffect } from "react";
import { MARKET_OPTIONS } from "configs/constants";
import { INDUSTRY_OPTIONS } from "configs/constants";
import FileUploadFormItem from "modules/shared/FileUploadFormItem";
import { PUERTO_RICO_STATES_AND_CITIES } from "modules/shared/pr-states-cities";
import { City, State } from "country-state-city";
import { loadAllCountries } from "helper/util";
import { NAME_REGEX } from "configs/constants";

const defaultOptionName = "Please Select";
const isoCodePR = "PR";

const OptionalLabel = (label) => (
  <div className="optional-label">
    <div className="left">{label}</div>
    <div className="right">Optional</div>
  </div>
);

const MyProfile = ({ profile, isFetching, onSubmit }) => {
  const [currentAddress, setCurrentAddress] = useState();
  const [countryStates, setCountryStates] = useState([]);
  const [stateCities, setStateCities] = useState([]);
  const [form] = Form.useForm();
  const addressAnchorRef = useRef(null);
  const [enableSummaryEmail, setEnableSummaryEmail] = useState(false);

  useEffect(() => {
    if (_isEmpty(profile)) return;
    form.setFieldsValue({ ...profile });
    setCurrentAddress({
      address: profile.address,
      lngLat: [profile.lng, profile.lat]
    });
  }, [form, profile]);
  useEffect(() => {
    setEnableSummaryEmail(profile?.isSummaryEmail);
    if (profile?.address) return;

    setTimeout(() => {
      addressAnchorRef.current.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }, [profile]);

  const handleSetAddress = (value) => {
    setCurrentAddress({ address: value.address, lngLat: value.lngLat });
  };

  const onChangeCountry = async (isoCodeCountry) => {
    if (!isoCodeCountry) return;
    form.setFieldsValue({ state: defaultOptionName });
    form.setFieldsValue({ city: defaultOptionName });
    let stateList = [];
    if (isoCodeCountry === isoCodePR) {
      stateList = PUERTO_RICO_STATES_AND_CITIES.map((item, idx) => ({
        name: item.state,
        isoCode: `${isoCodePR}-${idx}`
      }));
    } else {
      stateList = await State.getStatesOfCountry(isoCodeCountry);
    }
    setCountryStates(stateList);
  };

  const onChangeState = (stateName) => {
    const isoCodeState = _find(countryStates, { name: stateName })?.isoCode;

    if (!isoCodeState) return;
    form.setFieldsValue({ city: defaultOptionName });
    const isoCodeCountry = form.getFieldValue("country");
    let cities = [];
    if (isoCodeCountry === isoCodePR) {
      cities = _flattenDeep(
        PUERTO_RICO_STATES_AND_CITIES.filter((item) => item.state === stateName).map(
          (filteredItem) => [...filteredItem.cities]
        )
      ).map((city) => ({ name: city }));
    } else {
      cities = City.getCitiesOfState(isoCodeCountry, isoCodeState);
    }
    setStateCities(cities);
  };

  const handleOnFinish = async (values) => {
    const { address, lngLat } = currentAddress;
    onSubmit({
      ...values,
      address,
      lng: lngLat[0],
      lat: lngLat[1],
      logoPhoto: values?.logoPhoto?.id || undefined,
      addressField1: address
    });
  };

  return (
    <div className="common-form return-process-form">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={handleOnFinish}
        form={form}
      >
        <h2 className="title">My Profile</h2>
        <div className="form">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: NAME_REGEX,
                    message: "Invalid First Name"
                  }
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: NAME_REGEX,
                    message: "Invalid Last Name"
                  }
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  { type: "email", message: "Invalid Email" },
                  { required: true, message: "Required" }
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true, message: "Required" }]}
              >
                <PhoneInput country="ca" enableSearch />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Send summary email?"
                name="isSummaryEmail"
                valuePropName="checked"
              >
                <Switch
                  checked={enableSummaryEmail}
                  onChange={() => setEnableSummaryEmail(!enableSummaryEmail)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {enableSummaryEmail && (
                <Form.Item
                  name="summaryEmail"
                  label="Choose an email to send order confirmation"
                >
                  <Select
                    size="large"
                    placeholder="Please Select"
                    defaultValue={profile?.summaryEmail}
                  >
                    {profile?.summaryEmails.map((summEmail, index) => (
                      <Select.Option key={index + 1} value={summEmail}>
                        {summEmail}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider />
          {profile.clientType === "organization" && (
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <FileUploadFormItem
                    label="Company Logo"
                    name="logoPhoto"
                    setFieldsValue={form.setFieldsValue}
                    getFieldValue={form.getFieldValue}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Company Name"
                    name="clientName"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input size="large" placeholder="Company Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Support Email"
                    name="supportEmail"
                    rules={[
                      { type: "email", message: "Invalid Email" },
                      { required: true, message: "Required" }
                    ]}
                  >
                    <Input size="large" placeholder="Support Email" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Support Phone"
                    name="supportPhone"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <PhoneInput country="ca" enableSearch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Accounting Email"
                    name="accountingEmail"
                    rules={[
                      { type: "email", message: "Invalid Email" },
                      { required: true, message: "Required" }
                    ]}
                  >
                    <Input size="large" placeholder="Accounting Email" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Market"
                    name="market"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select size="large" placeholder="Please Select">
                      {MARKET_OPTIONS.map((market) => (
                        <Select.Option key={market} value={market}>
                          {market}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Industry"
                    name="industry"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      size="large"
                      placeholder="Please Select"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.children ?? "").includes(input)
                      }
                    >
                      <Select.Option value="">Please Select</Select.Option>
                      {INDUSTRY_OPTIONS.map((industry) => (
                        <Select.Option key={industry} value={industry}>
                          {industry}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <div style={{ opacity: 0 }} ref={addressAnchorRef}></div>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Address (move pin to exact location)"
                name="address"
                rules={[{ required: true, message: "Required" }]}
              >
                <AddressInput
                  className={!profile.address ? "outlined-focus" : ""}
                  placeholder="Address"
                  onSelect={(value) => handleSetAddress(value)}
                  address={currentAddress}
                />
              </Form.Item>
              <div className="map">
                <DefaultMapbox address={currentAddress} setAddress={handleSetAddress} />
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Address Line 1"
                name="address"
                rules={[{ required: true, message: "Required" }]}
              >
                <AddressInput
                  placeholder="Address Field 1"
                  onSelect={(value) => handleSetAddress(value)}
                  address={currentAddress}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={OptionalLabel("Address Line 2")} name="addressField2">
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  size="large"
                  placeholder="Please Select"
                  onChange={onChangeCountry}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes((input ?? "").toLowerCase())
                  }
                >
                  {loadAllCountries().map((country) => (
                    <Select.Option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={OptionalLabel("State")} name="state">
                <Select
                  size="large"
                  placeholder="Please Select"
                  onChange={onChangeState}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "").includes(input)
                  }
                >
                  {[{ name: defaultOptionName, isoCode: "" }, ...countryStates].map(
                    (state) => (
                      <Select.Option key={`state-${state.isoCode}`} value={state.name}>
                        {state.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={OptionalLabel("City")} name="city">
                <Select
                  size="large"
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "").includes(input)
                  }
                >
                  {[{ name: defaultOptionName }, ...stateCities].map((city) => (
                    <Select.Option key={`city-${city.name}`} value={city.name}>
                      {city.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={OptionalLabel("Zip Code")} name="postalAddress">
                <Input size="large" placeholder="Zip Code" maxLength="5" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="form-footer">
          <Button loading={isFetching} type="primary" htmlType="submit">
            Update
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MyProfile;
