import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
/* these needed for map, but for now we're just showing the status
import RoutingMap from "../components/RoutingMap";
import classes from "./GoingNextPage.module.scss";
import { Button } from "antd";
import { clsx } from "../store/utils";
import { useFetchOnGoingOrder } from "../store/usecase";
import { Marker } from "react-mapbox-gl";
import StopOnMap from "../components/StopOnMap";
import PolylineOnMap from "../components/PolylineOnMap";
import { AccessTimeIcon, LocalShippingIcon } from "../components/Icons";
import { RouteLocationStatus } from "../store/model";
*/
import request from "helper/request";
import moment from "moment";

export default function PoolTracking() {
  const { token } = useParams();
  const [packageDetails, setPackageDetails] = useState({});
  //const value = useFetchOnGoingOrder(token); // for fetching SaaS order

  // Get Pool Package Details from public endpoint
  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const response = await request.get(
          window.REACT_APP_API_ENDPOINT + "/public/pool/full-tracking/package/" + token
        );
        setPackageDetails(response);
        console.log("debug, package: ", response);
      } catch (error) {
        console.error("Error fetching package:", error);
      }
    };
    fetchPackageDetails();
  }, [token]);

  // Rough ETA - add a day from pickup, unless sunday, add 2
  const getAdjustedETA = (date) => {
    let eta = moment(date).add(1, "days");

    // If the ETA falls on Sunday (0), add one more day
    if (eta.day() === 0) {
      eta = eta.add(1, "days");
    }

    return eta.format("dddd, YYYY-MM-DD");
  };

  return (
    <div>
      <p>Order ID: {packageDetails?.orderV2_id}</p>
      <p>Package ID: {packageDetails?.id}</p>
      <p>Barcode: {packageDetails?.barcode}</p>
      <br />
      <p>Dropoff Address: {packageDetails?.dropoffAddress}</p>
      <br />
      <strong>
        <p>
          Status:&nbsp;
          <Status packageDetails={packageDetails} />
        </p>
        <p>ETA: {getAdjustedETA(packageDetails?.pickupDate)}</p>
      </strong>
    </div>
    /* This was the /s/ OnGoingPage map, etc, once we have linked details we'll add this back in

    <div className={classes.container}>
      <div className={classes.map}>
        <RoutingMap bounds={value.bounds} center={value.orderLocation}>
          <DriverMarker location={value.driverLocation} />

          <DestinationMarker location={value.orderLocation} />

          <PolylineOnMap coords={value.coords} color={"#43C1C2"} />
        </RoutingMap>
      </div>

      <div className={classes.bottomSheet}>
        <label>Your order is on the way</label>
        <p>{value.address}</p>
        <div className={classes.hLine} />
        {value.hasDriver() && (
          <div className={classes.driver}>
            <div className={classes.avatar}>{value.driverShortName}</div>
            <label>{value.driverName}</label>
            <p>{value.vehicleModelAndColor}</p>
            <span className={classes.plateNumber}>{value.plateNumber}</span>
          </div>
        )}
      </div>
      <Status packageDetails={packageDetails} />
    </div>
    */
  );
}

function Status({ packageDetails }) {
  switch (packageDetails.status) {
    case "submitted":
      return "Submitted. Package has been uploaded to Flexio.";
    case "pending":
      return "Pending. Package has been acknowledged by Flexio.";
    case "at_warehouse":
      return "At the warehouse. Package has been picked up and at warehouse for outboud delivery.";
    case "pickup_scheduled":
      return "Pickup scheduled. Package will be picked up from shipper soon.";
    case "pickup_in_progress":
      return "Pickup in progress. Driver is out for pickup route.";
    case "pickup_completed":
      return "Pickup completed. Package is in transit from pickup to warehouse.";
    case "pickup_failed":
      return "Pickup failed. Package pickup failed. Perhaps a bad address?";
    case "return_scheduled":
      return "Return Scheduled. Package will be returned to shipper soon.";
    case "return_in_progress":
      return "Return in progress. Driver is out for return route.";
    case "return_completed":
      return "Return completed. Package has been returned to shipper.";
    case "return_failed":
      return "Return failed. Please contact the shipper to have them confirm shipping details!";
    case "dropoff_scheduled":
      return "Dropoff scheduled. Package is in transit from warehouse to dropoff.";
    case "dropoff_in_progress":
      return "Dropoff in progress. Driver is out for dropoff route.";
    case "dropoff_completed":
      return "Dropoff completed. Package has been successfully delivered.";
    case "dropoff_failed":
      return "Dropoff failed. Wrong address? Gates or dogs? Please provide dropoff details!";
    case "cancelled":
      return "Cancelled. Package has been cancelled.";
    default:
      return <></>;
  }
}

// TODO - Pool / SaaS linking
// lookup SaaS Orders (Routes / Stops) by package ID as Internal ID
// show status of THAT, e.g. on the map.

/*
const statusColorOf = (status) => {
  switch (status) {
    case RouteLocationStatus.Failed:
      return classes.red;
    default:
      return classes.gray;
  }
};

function SimpleStatus({ packageDetails }) {
  return (
    <div
      className={clsx(
        classes.status,
        classes.simple,
        statusColorOf(packageDetails.status)
      )}
    >
      {packageDetails.statusName}
    </div>
  );
}

function OnGoingStatus({ packageDetails }) {
  return (
    <div className={clsx(classes.status, classes.eta)}>
      <AccessTimeIcon /> <span>ETA</span> <b>{packageDetails.eta}</b>
    </div>
  );
}

function DriverMarker({ location }) {
  if (!location) return <></>;
  return (
    <Marker coordinates={[location.lng, location.lat]}>
      <div className={classes.shipping}>
        <LocalShippingIcon />
      </div>
    </Marker>
  );
}

function DestinationMarker({ location }) {
  if (!location) return <></>;
  return (
    <Marker coordinates={[location.lng, location.lat]}>
      <StopOnMap borderColor={"#0078D4"} backgroundColor={"#FFF"} />
    </Marker>
  );
}
*/
